import { errorCodes } from "../../../services/errorCodes";
import { t } from "common/localization/translate";

export const getErrorTextByErrorCode = (errorCode) => {
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case errorCodes.ERROR_TYPE_USER_NOT_EXISTS:
            return t('support.sendNotificationScreen.userNotExsist');
        case errorCodes.ERROR_TYPE_INVALID_DATA:
            return t('support.sendNotificationScreen.invalidData');
        case errorCodes.ERROR_TYPE_ID_TOO_SHORT:
            return t('support.sendNotificationScreen.idNumTooShort');
        case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return t('support.sendNotificationScreen.onlyNumbers');
        default:
            return t('support.sendNotificationScreen.userNotExsist');
    }
};