import React from "react";
import { ErrorText } from "./components/errorText";
import { RemovedSpecialCharactersText } from "./components/removedSpecialCharactersText";
import { SuccessText } from "./components/successText";

export const HelperTexts = (props) => {
  return (
    <div>
      <ErrorText />
      <SuccessText />
      <RemovedSpecialCharactersText />
    </div>
  );
};
