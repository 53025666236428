import { parsePhoneNumberFromString } from "libphonenumber-js/max";

export const validatePhoneNumber = (phone, shouldOnlyValidateIsraeliNumber) => {
  if (phone == "" || phone == undefined) {
    return false;
  }

  const phoneNumberIL = parsePhoneNumberFromString(phone, "IL");
  const phoneNumberEN = parsePhoneNumberFromString(phone, "US");

  if (phoneNumberIL && phoneNumberIL.isValid()) {
    return true;
  }

  if (phoneNumberEN && phoneNumberEN.isValid() && !shouldOnlyValidateIsraeliNumber) {
    return true;
  }

  return false;
};
