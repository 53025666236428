import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import { t } from "common/localization/translate";
import { TotalImpressionWaveChart } from './components/totalImpressionWaveChart'
import ChangePeriodDropDown from "./components/changePeriodDropDown";
import Grid from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    height: 500
  },
  header: { flexDirection: "row", display: "flex" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    width: 48,
    display: "flex",
    justifyContent: "center"
  },
  row: {
    height: "100%",
  }
}));

export const TotalImpressionChart = observer(() => {
  const classes = useStyles();

  return (
    <Grid item lg={12} xl={9} xs={12} className={classes.row}>
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        title={t("business.home.impressionGraph.title")}
        action={<ChangePeriodDropDown />}
      />
      <Divider />
      <CardContent>
        <TotalImpressionWaveChart />
      </CardContent>
    </Card>
    </Grid>
  );
});


