import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenHomeStore } from "../../../store";
import { makeStyles } from "@mui/styles";
import { t } from 'common/localization/translate';


const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    }
}));


export const SuccessSendMonthlyReportPopUp = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();
    const handleClose = () => {
        store.isSuccessSentMonthlyReport = false;
    };


    return (
        <div>
            <Dialog open={store.isSuccessSentMonthlyReport} >
                <DialogTitle className={classes.title} >{t("suppliers.projectManagerHome.monthlyReportPopUp")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("suppliers.projectManagerHome.monthlyReportIsSucceededPopUp",{email: store.projectManagerEmail})}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus >{t("suppliers.projectManagerHome.monthlyReportIsOKPopUp")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})