import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { colors, Tooltip } from "@mui/material";
import Label from "common/ui/label";
import { t } from "common/localization/translate";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(theme => ({
  label: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  colorPrimary: {
    color: "white",
    height:10
  }
}));


export const PercentageBudgetUsedLabel = observer((props) => {
  const classes = useStyles();
  const {isFetching, percentageBudgetUsed} = props;
  const count = `${percentageBudgetUsed}%`;
  const middleComponent = isFetching ? (
    <CircularProgress color="primary" size = {10} classes={{ colorPrimary: classes.colorPrimary }} />
  ) : count;

  if(!percentageBudgetUsed) {
      return null;
    }

  return (
    <Tooltip
      title={t(
        "suppliers.infoCubes.percentageToolTip"
      )}
      placement="bottom"
    >
      <div>
        <Label
          className={classes.label}
          color={colors.grey[600]}
          variant="contained"
        >
          {middleComponent}
        </Label>
      </div>
    </Tooltip>
  );
});
