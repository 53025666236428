import { t } from "common/localization/translate";
import { writeToRow } from "./writeToRow";

export function createSheetReferal(sheet, storeHome) {
    const { referralLeaders } = storeHome;
    const col1 = t("excel.sheet.user");
    const col2 = t("excel.sheet.addedFriends");
    writeToRow(sheet, [col1, col2], 1);
    let rowNumber = 2;
    if(!referralLeaders){
        return;
    }
    referralLeaders.forEach(({ name, count }) => {
        writeToRow(sheet, [name, count], rowNumber);
        rowNumber++;
    });
}