import { t } from "common/localization/translate";
export const confirmationsErrors =
{
    onlyNumbers: t("screenConfirmationUser.errors.onlyNumbers"),
    tooShort: t("screenConfirmationUser.errors.idNumberTooShort"),
    userNotExsist: t("screenConfirmationUser.errors.userNotExsist"),
    invalidInput: t("screenConfirmationUser.errors.invalidInput")
}


