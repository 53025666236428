import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import { t } from "common/localization/translate";
import { CouponsTable } from "./components/CouponsTable";
import { createScreenConfirmHideCouponsStore, ScreenConfirmHideCouponsDataProvider } from "./store";
import { ConfirmHideCouponPopUp } from "./components/popUps/ConfirmHideCouponPopUp";
import { SuccessCancelCouponPopUp } from "./components/popUps/SuccessCancelCouponPopUp";
import { RestoreCouponPopUp } from "./components/popUps/RestoreCouponPopUp";
import { ConfirmAndCancelButtons } from "./components/ConfirmAndCancelButtons";
import { CouponCancellationUnsusccessfulPopup } from "./components/popUps/CouponCancellationUnsusccessfulPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  }
}));
export const ConfirmHideCoupons = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenConfirmHideCouponsStore(rootStore));
  const classes = useStyles();

  useEffect(() => {
    store.getScreenData();
  }, []);

  return (
    <ScreenConfirmHideCouponsDataProvider value={store}>
      <Page className={classes.root} title={t("screenCouponsUser.title")}>
        <ConfirmAndCancelButtons />
        <CouponsTable />
        <RestoreCouponPopUp />
        <ConfirmHideCouponPopUp />
        <SuccessCancelCouponPopUp />
        <CouponCancellationUnsusccessfulPopup />
      </Page>
    </ScreenConfirmHideCouponsDataProvider>
  );
});
