import React from "react";
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";

export const StatusText = observer((props) => {
    const classes = useStyles();
    const entitlementStatusStyle = props.isPositive ? classes.entitledText : classes.notEntitledText;
    return (
        <Grid item className={classes.container} lg={12} md={12}>
            <Typography className={classes.text}>{props.descriptionText}</Typography>
            <Typography className={clsx(entitlementStatusStyle, classes.text)}>{props.statusText}</Typography>
        </Grid>
    );
});

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: "14px",
        display: 'inline-block',
        paddingLeft: 5
    },
    notEntitledText: {
        color: 'red'
    },
    entitledText: {
        color: 'green'
    },
    container: {
        paddingBottom: 0,
        paddingTop: 0
    }
}));