/* eslint-disable import/first */
import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { configureMobx } from "./common/configureMobx";
import { createBrowserHistory } from "history";
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/index.scss";
import { renderRoutes } from "react-router-config";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import moment from "moment";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { StyledEngineProvider } from '@mui/material/styles';
import theme from "./theme";
import { routes } from "./routes";
import { RootStoreProvider } from "./commonStores/analytics/rootStoreContext";
import { createRootStore } from "commonStores/analytics/rootStore";
import ThemeProvider from "theme/themeProvider";
import { he } from "date-fns/locale";
import { setDirection } from "utils/direction/setDirection";

configureMobx();
const browserHistory = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const rootStore = createRootStore(browserHistory.location.pathname);

function App() {

  useEffect(() => {
    setDirection();
  }, []);
 
  return (
    <RootStoreProvider value={rootStore}>
        <StylesProvider jss={jss}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider libInstance={moment} adapterLocale={he}>
                <Router history={browserHistory}>{renderRoutes(routes)}</Router>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </StylesProvider>
    </RootStoreProvider>
  );
}

export default App;