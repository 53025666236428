import React from "react";
import { TableRow, TableCell, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  iconV: {
    fontSize: "21px",
    display: "inline",
  }
}));

export const MailingConfirmationsWithoutDates = (props) => {
  const classes = useStyles();
  const { columns, confirmationsData } = props;
  const iconToShow = confirmationsData.mailingConfirmation ? (<Box className={classes.iconV}>✔</Box>) : "❌";
  return (
    <TableRow>
      <TableCell align="right">{columns.approvalMailing}</TableCell>
      <TableCell align="right">{iconToShow}</TableCell>
    </TableRow>
  );
};
