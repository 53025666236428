export const COLORS = {
    RUMBLE_GREEN: '#4ed2a8',
    BACKGROUND_COLOR: '#f4f6f8',
    OUTLINE_GRAY: '#c4c1c0'
}

export const clalitColors = {
    CLALIT_LIGHT_BLUE_8 : '#26C1DE',
    CLALIT_TEXT_DARK_BLUE_3 : '#1B4B7C',
    CLALIT_MAIN: '#243A7E',
    CLALIT_GREEN_3 : '#26DE94',
    CLALIT_PURPLE_2 : '#4F20E5'
}