import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { observer } from "mobx-react";
import { useScreenSendNotificationStore } from "../store/index";
import { NotificationDetails } from './notificationDetails';
import { SendButtons } from './sendButtons';
import { UserDetails } from './userDetails';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SuccessSendNotificationPopUp } from './successSendNotificationPopUp';
import { SendNotificationPopUpErrorMessage } from './sendNotificationPopUpErrorMessage';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 500,
        "& > *": {
            height: "100%",
            padding: theme.spacing(0.5),
        }
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: theme.palette.primary.main
    }
}));

export const NotificationCard = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    if (!store.userName && !store.isSendNotificationToAllUsers) {
        return null;
    }

    return (
        <Grid className={classes.container}>
            <Card>
                <CardContent>
                    <UserDetails />
                    {!store.isSendNotificationToAllUsers && <Divider className={classes.divider} />}
                    <NotificationDetails />
                </CardContent>
                <SendButtons />
                <SuccessSendNotificationPopUp />
                <SendNotificationPopUpErrorMessage />
            </Card>
        </Grid>
    );
})