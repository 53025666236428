import Excel from "exceljs/dist/exceljs";
import { createSheet } from "./createSheet";
import { downloadWorksheet } from "../downloadWorksheet";
import { setSheetProps } from "../setSheetProps";
import { t } from "common/localization/translate";

const COL_1_WIDTH = 20;
const TOTAL_COLUMNS = 9;

export async function exportSinglePoliceDataToExcel(store) {
    const { userName, userIdNum } = store;
    const fileName = `${t("screenHealthAndFitnessData.excel.filename.oneCop.startText")} ${userName}_${userIdNum}`;
    const tableName = `${t("screenHealthAndFitnessData.excel.filename.oneCop.startText")} ${userName} ${userIdNum}`;
    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(t("screenHealthAndFitnessData.excel.filename.oneCop.sheetName"));

    setSheetProps({ sheets: [sheet], firstColWidth: COL_1_WIDTH, otherColsWidth: COL_1_WIDTH, totalCols: TOTAL_COLUMNS });
    createSheet(sheet, store, tableName);
    downloadWorksheet(workbook, fileName);
}