import React from "react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles"
import { useScreenDailyStepsAndGoalsStore } from "../store";
import { segmentsPaths } from '../../../../commonStores/analytics/userInfoStore/logic/getSegmentPathById';

const useStyles = makeStyles((theme) => ({
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    }
  }
}));

export const SubTitleStepsAndGoals = observer(() => {
  const classes = useStyles();
  const store = useScreenDailyStepsAndGoalsStore();
  const segmentId = store.rootStore.userInfoStore.currentSegmentId;
  
  if (segmentId == segmentsPaths.CLALIT.id) { 
    return null;
  }
  return (
    <Grid className={classes.container} >
      <Typography variant="subtitle2">
        {t("screenDailyStepsAndGoals.subTitle.note")}
      </Typography>
    </Grid>
  );
});