import React from "react";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { CardActions } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useScreenEditUserInfoStore } from "screens/support/editUserInfo/store";
import { CommonButton } from "common/ui/commonButton";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    alignSelf: "center",
    height: "100%",
    alignItems: "center",
  },
}));

export const SaveButton = observer((props) => {
  const classes = useStyles();
  const store = useScreenEditUserInfoStore();

  const { submitUserChanges, isSaveButtonDisabled } = store;

  const handleSubmit = async (event) => {
    event.preventDefault();
    submitUserChanges();
  };

  return (
    <Grid item md={3} xs={12}>
      <CardActions className={classes.container}>
        <CommonButton onClick={handleSubmit} disabled={isSaveButtonDisabled}>
          {t("screenEditUserInfo.button.save")}
        </CommonButton>
      </CardActions>
    </Grid>
  );
});
