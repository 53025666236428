import React from "react";
import IdleTimer from 'react-idle-timer';
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { getSegmentPathById } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { observer } from "mobx-react-lite";
import { getIsWithoutSegmentPath } from '../../commonStores/analytics/userInfoStore/logic/getIsWithoutSegmentPath';
import { logoutServer } from "./server/logoutServer";

const THIRTY_MINUTES_IN_MILLISEC = 1800000;
export const IdleTimerContainer = observer((props) => {
    const rootStore = useRootStore();
    const handleLogout = async () => {
        const { dashboardType, clearUserInfoStore, currentSegmentId } = rootStore.userInfoStore;

        if (!currentSegmentId || getIsWithoutSegmentPath(dashboardType)) {
            clearUserInfoStore();
            await logoutServer();
            props.history.push(`/${dashboardType}/login`);
            return;
        }
        const segmentPath = getSegmentPathById(currentSegmentId);
        clearUserInfoStore();
        await logoutServer();
        props.history.push(`/${dashboardType}/login/${segmentPath}`);

    };
    return (
        <IdleTimer timeout={THIRTY_MINUTES_IN_MILLISEC} onIdle={handleLogout} ></IdleTimer>
    )

});

