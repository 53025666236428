export function getPurchasesTableData(store) {
  const { couponsPurchasesCount } = store;
  const tableDataArray = couponsPurchasesCount
    ? couponsPurchasesCount.map(item => [
        item.title,
        item.businessName,
        Number(item.count)
      ])
    : [];
  return tableDataArray;
}
