import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";
import { useBillingReportSystemStore } from "../../store/index"; 
import { t } from "common/localization/translate";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 200,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        }
    }
}));

export const SubmitCodesForRejectionButton = observer(() => {
    const store = useBillingReportSystemStore();
    const classes = useStyles();
    let disabled;

    const onClick = async () => {
        await store.sendRejectCodes(store.codes);
    }
    if(!store.codes){
        disabled = true;
    }
    const getReportButtonContent = store.isRejectingCodes ? <CircularProgress color='white' size={20} /> : t("suppliers.billingReportSystem.rejectCodes.text");
    return (
        <Grid item xs={12} spacing={3}>
            <Button variant="contained" className={classes.buttons} onClick={onClick} disabled={disabled}> {getReportButtonContent} </Button>
        </Grid>
    )
});