import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenConfirmHideCouponsStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { getPopUpText } from "../../../coupons/store/logic/getPopUpText";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.white,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 14,
    },
  },
  content: { marginTop: 8 },
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    color: theme.palette.white,
    marginLeft: 21,
    marginTop: 18,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondGraphColor,
      color: theme.palette.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const CouponCancellationUnsusccessfulPopup = observer(() => {
  const store = useScreenConfirmHideCouponsStore();
  const {
    couponName,
    isSuccessCancelCoupon,
    deleteCouponErrorCode,
    setDeleteCouponErrorCode,
  } = store;
  const classes = useStyles();

  const handleClose = () => {
    setDeleteCouponErrorCode(null);
  };

  if (isSuccessCancelCoupon) {
    return null;
  }

  const errorCode = store.deleteCouponErrorCode;
  const text = getPopUpText({ couponName, errorCode });
  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={deleteCouponErrorCode}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ width: 500, height: 180 }}>
          <DialogTitle className={classes.root} id="responsive-dialog-title">
            {t("screenCouponsUser.cancelCoupon")}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText>
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonsContainer}>
            <Button
              className={classes.buttons}
              size="small"
              type="submit"
              variant="contained"
              onClick={handleClose}
            >
              {t("screenCouponsUser.popUp.closeButton")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});
