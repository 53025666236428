import { START_DATA_ROW } from "./exportAllPoliceDataToExcel/createSheet";
import { fillRowHeaders } from "./fillRowHeaders";
import { styles } from "./styles";

const TITLE_HEIGHT = 20;
const DATA_HEIGHT = 18;
const ONLY_ONE_START_DATA_ROW = 4;
const ONLY_ONE_END_DATA_ROW = 6;
const ONLY_ONE_END_DATA_COL = 9;
const ALL_END_DATA_COL = 11;

export function styleSheet(sheet, endRow, mergedStr, isOnlyOne) {
    const stageHeaderRow = sheet.getRow(2);
    stageHeaderRow.height = TITLE_HEIGHT;
    stageHeaderRow.fill = styles.darkBlueFill;
    stageHeaderRow.font = styles.whiteBold;

    const mergedStageHeader = sheet.getCell(mergedStr);
    mergedStageHeader.alignment = styles.alignmentCenter;
    mergedStageHeader.border = styles.border;

    const colHeaderRow = sheet.getRow(3);
    colHeaderRow.height = TITLE_HEIGHT;
    colHeaderRow.eachCell((cell) => {
        cell.alignment = styles.alignmentCenter;
        cell.fill = styles.grayFill;
        cell.border = styles.border;
    });

    for (let row = START_DATA_ROW; row <= endRow; row++) {
        const dataRow = sheet.getRow(row);
        dataRow.height = DATA_HEIGHT;

        const dataColEnd = isOnlyOne ? ONLY_ONE_END_DATA_COL : ALL_END_DATA_COL;
        for (let col = 1; col <= dataColEnd; col++) {
            dataRow.getCell(col).alignment = styles.alignmentCenter;
            dataRow.getCell(col).border = styles.border;
        }
    }

    if (isOnlyOne) {
        fillRowHeaders(ONLY_ONE_START_DATA_ROW, ONLY_ONE_END_DATA_ROW, sheet);
    }
}