import React from "react";
import clsx from "clsx";
import { t } from "common/localization/translate";
import { MailingConfirmations } from "./confirmationsMailing";
import { TermsConfirmations } from "./terms";
import { UserNameConfirmations } from "./userName";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  table: {
    width: '100%',
    minWidth: '30vw'
  },

  actions: {
    flexDirection: "column",
    alignItems: "flex-start",
    "& > * + *": {
      marginLeft: 0,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  iconV: {
    fontSize: "21px",
    display: "inline",
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  },
}));

export const ConfirmationTable = (props) => {
  const { confirmationsData, className, errorType } = props;
  const classes = useStyles();
  const { columns } = getTableData();
  if (errorType || !confirmationsData) {
    return null;
  }
  return (
    <Grid className={classes.container} container item xs={12}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader title={t("verticalTableConfirmation.table.title")} />
        <Divider />
        <CardContent className={classes.content}>
          <Table className={classes.table}>
            <TableBody>
              <UserNameConfirmations
                columns={columns}
                confirmationsData={confirmationsData}
              />
              <TermsConfirmations
                columns={columns}
                confirmationsData={confirmationsData}
              />
              <MailingConfirmations
                columns={columns}
                confirmationsData={confirmationsData}
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  );
};

const getTableData = () => {
  const columns = {
    userName: t("verticalTableConfirmation.table.col.userName"),
    approvalPolicy: t("verticalTableConfirmation.table.col.approvalPolicy"),
    approvalMailing: t("verticalTableConfirmation.table.col.approvalMailing"),
    approvalMailingDate: t(
      "verticalTableConfirmation.table.col.approvalMailingDate"
    ),
  };
  return { columns };
};

export default ConfirmationTable;
