import React from "react";
import clsx from "clsx";
import { Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from "@mui/styles";
import { formatNumber } from "common/formatNumber";
import { getTopGradient } from "./getTopGradient";
import { getBottomGradient } from "./getBottomGradient";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    overflowX: 'auto',
    height: 300,
  }
}));

const WaveChart = props => {
  const { linesData, className, labels, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();

  const data = () => {
    const dataSets = linesData ? linesData.map(({ dataArray, textLabel }, index) => {
      return {
        label: textLabel,
        data: dataArray,
        fill: (canvas) => {
          return index == 0 ? getTopGradient({ canvas, theme }) : getBottomGradient({ canvas, theme });
        },
        borderColor: index == 0 ? theme.palette.primary.main : theme.palette.primary.secondGraphColor,
        pointBorderColor: theme.palette.white,
        pointBorderWidth: 2,
        pointRadius: 6,
        pointBackgroundColor: index == 0 ? theme.palette.primary.main : theme.palette.primary.secondGraphColor
      }
    }) : [];

    return {
      datasets: dataSets,
      labels
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      steps: 100,
      scale: false,
    },
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      x: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            color: theme.palette.text.secondary
          }
        },
      y: 
        {
          grid: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            color: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 7,
            callback: value => {
              return value;
            }
          }
        }
    },
    interaction: {
      mode: 'index'
    },
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        caretSize: 20,
        yPadding: 20,
        xPadding: 20,
        borderWidth: 1,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.white,
        footerFontColor: theme.palette.text.secondary,
        callbacks: {
          labelTextColor: () => theme.palette.text.secondary,
          bodyFontColor: () => theme.palette.text.secondary,
          title: () => { },
          label: tooltipItem => {
            const index = tooltipItem.datasetIndex;
            const textLabel = linesData[index].textLabel;
            const label = `${tooltipItem.label}: ${formatNumber(tooltipItem.formattedValue)} ${textLabel}`;
            return label;
          }
        }
      }
    },
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Line options={options} data={data()} />
    </div>
  );
};

export default WaveChart;