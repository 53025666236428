import React from "react";
import { TableRow, TableCell } from "@mui/material";

export const UserNameConfirmations = (props) => {
  const { confirmationsData, columns } = props;

  return (
    <TableRow>
      <TableCell align="right">{columns.userName}</TableCell>
      <TableCell align="right">{confirmationsData.name}</TableCell>
    </TableRow>
  );
};