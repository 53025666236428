import { t } from "common/localization/translate";
import { writeToRow } from "./writeToRow";

export function createSheetSum(sheetSum, storeHome) {
  const { selectedSegmentName } = storeHome;

  let col1 = t("excel.sheet.averageImprovement", { selectedSegmentName });
  let col2 = t("excel.sheet.membersNumber", { selectedSegmentName });
  let col3 = t("excel.sheet.membersNumber.lastWeek", { selectedSegmentName });
  let col4 = t("excel.sheet.coinsTotal", { selectedSegmentName });
  let weeklyImprovementColumn = t("excel.sheet.weeklyImprovement", { selectedSegmentName });

  const { totalCurrentSegmentMembers, sumOfCoinsOfCurrentSegmentUsers, lastWeekNewUsers, monthlyImprovement, weeklyImprovement } = storeHome;
  const colsInDoc = [col1, col2, col3, col4];
  const valuesInDoc = [monthlyImprovement, totalCurrentSegmentMembers, lastWeekNewUsers, sumOfCoinsOfCurrentSegmentUsers];

  if (weeklyImprovement) {
    colsInDoc.splice(1, 0, weeklyImprovementColumn);
    valuesInDoc.splice(1, 0, weeklyImprovement);
  }
  writeToRow(sheetSum, colsInDoc, 1);
  writeToRow(sheetSum, valuesInDoc, 2);
}