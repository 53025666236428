import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenValidationCodeStore, ScreenValidationCodeStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { SuppliersList } from "./components/suppliersList";
import { ValidationsList } from './components/validationsList';
import { CurrentValidation } from './components/currentValidation';
import { ChangeSupplierValidationButton } from './components/changeSupplierValidation';
import { SuccessChangeValidationPopUp } from './components/successChangeValidationPopUp'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    width: "100%",
    height: "100%",

  },
}));
export const ValidationCode = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenValidationCodeStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
    store.getScreenData()
  }, []);
  if (!store.suppliersList) {
    return null;
  }

  return (
    <ScreenValidationCodeStoreProvider value={store}>
      <Page title={t("suppliers.home.suuplierDashboardTitle")} className={classes.root}  >
        <Grid container spacing={3} >
          <SuppliersList />
          <CurrentValidation />
          <ValidationsList />
          <ChangeSupplierValidationButton />
          <SuccessChangeValidationPopUp />
        </Grid>
      </Page>
    </ScreenValidationCodeStoreProvider>
  )
});

