import React, { Fragment, Suspense, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import { Topbar } from './components';
import { removeTokenFromLocalStorage } from 'commonStores/analytics/localStorage/localPersistence';
import { logoutServer } from 'layouts/Dashboard/server/logoutServer';
import { setDirection } from 'utils/direction/setDirection';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  }
}));

const Auth = props => {
  const { route } = props;

  useEffect(() => {
    setDirection();
    removeTokenFromLocalStorage();
    logoutServer();
  }, []);

  const classes = useStyles();

  return (
    <Fragment>
      <Topbar />
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </Fragment>
  );
};

Auth.propTypes = {
  route: PropTypes.object
};

export default Auth;
