import { languages } from "common/localization/languages";
import { t } from "common/localization/translate";

export function filterScreensBySegmentFeaturesAndPermissions({
  navigationConfig,
  isShowTrainesInfoScreen,
  isShowSubSegmentUsersScreen,
  isShowMultiSegmentUsersScreen,
  isShowAppOpenByHours,
  isShowOrderDetailsScreen,
  isShowStepsAndGoalsScreen,
  isShowHideCouponTypesScreen,
  isShowSendNotificationScreen,
  isAllowedHideCouponTypes,
  isAllowedToSendNotification,
  isAllowedToHideCoupons,
  isShowSwitchBusinessScreen,
  isProjectManager,
  isShowHealthAndFitnessData,
  isHideCoachesInfoScreen,
  isSupplier,
  isAllowedToCheckEntitlement,
  language,
}) {
  language = [languages.ENGLISH, languages.SPANISH].includes(language)
    ? languages.ENGLISH
    : languages.HEBREW;
  const filteredMenu = { pages: navigationConfig[0].pages };
  if (!isShowStepsAndGoalsScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.support.dailyStepsAndGoals", null, language)
    );
  }
  if (!isShowOrderDetailsScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) => item.title !== t("screenOrderDetails.title", null, language)
    );
  }
  if (!isShowTrainesInfoScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.staff.title") &&
        item.title !== t("sideBar.traines.title", null, language)
    );
  }
  if (isHideCoachesInfoScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) => item.title !== t("sideBar.staff.title", null, language)
    );
  }
  if (!isShowHealthAndFitnessData) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.healthAndFitnessData.title", null, language)
    );
  }
  if (!isShowSubSegmentUsersScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.userConfirmaions.title", null, language) &&
        item.title !== t("sideBar.subSegmentUsers.title", null, language)
    );
  }
  if (!isShowMultiSegmentUsersScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.multiSegmentUsers.title", null, language)
    );
  }
  if (!isShowAppOpenByHours) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) => item.title !== t("sideBar.appOpenByHours.title", null, language)
    );
  }

  if (!isShowHideCouponTypesScreen || !isAllowedHideCouponTypes) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.appOpenByHours.hideCoupon", null, language)
    );
  }

  if (!isAllowedToSendNotification || !isShowSendNotificationScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !==
        t("sideBar.support.sendNotificationToUser", null, language)
    );
  }

  if (!isAllowedToHideCoupons) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !== t("sideBar.support.confirmHideCoupons", null, language)
    );
  }

  if (!isShowSwitchBusinessScreen) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !==
        t("business.sideBar.switchBusiness.title", null, language)
    );
  }
  if (isProjectManager == 24) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title == t("suppliers.sideBar.validations", null, language) ||
        item.title ==
          t(
            "suppliers.billingReportSystem.projectManagerSupplier.title",
            null,
            language
          ) ||
        item.title == t("suppliers.supplierAndCouponReports.title") ||
        item.title == t("suppliers.projectManagerHome.title", null, language) ||
        item.title == t("sideBar.home.title", null, language)
    );
  }

  if (isProjectManager == 17) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title == t("suppliers.sideBar.validations", null, language) ||
        item.title ==
          t(
            "suppliers.billingReportSystem.projectManagerSupplier.title",
            null,
            language
          ) ||
        item.title == t("suppliers.supplierAndCouponReports.title") ||
        item.title == t("sideBar.home.title", null, language)
    );
  }

  if (isSupplier) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) => item.title == t("sideBar.home.title", null, language)
    );
  }

  if (!isAllowedToCheckEntitlement) {
    filteredMenu.pages = filteredMenu.pages.filter(
      (item) =>
        item.title !==
        t("screenEntitlementData.entitlementSideBarTitle", null, language)
    );
  }

  const { pages } = filteredMenu.pages ? filteredMenu : navigationConfig[0];
  return pages;
}
