import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { he } from "date-fns/locale";
import { ChangeMonthPopUp } from './changeMonthPopUp';
import moment from 'moment';
import { InputLabel } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        fontSize: 14,
    },
    toolTipIcon: {
        color: theme.palette.primary.main
    },
    label: {
        fontSize: 14,
        fontWeight: 'bold',
    }
}));

export const SelectDate = observer((props) => {
    const { store, isProjectManager } = props;
    const classes = useStyles();
    const defalutDate = moment().subtract(1, 'months').toDate();
    const maxDate = new Date(moment().subtract(1, 'month').endOf('month').toDate());
    const minDate = new Date(moment().subtract(1, 'years').startOf('month').toDate());

    const handleDateChange = (selectedDate) => {
        selectedDate = moment(selectedDate);
        store.isValidOtpCode = false;
        if (selectedDate > maxDate || selectedDate < minDate) {
            store.month = defalutDate;
            store.changeMonthDecision = false;
            return;
        }
        if (moment(store.month).isSame(selectedDate, 'month')) {
            return;
        }
        if (!moment(defalutDate).isSame(selectedDate, 'month') && !moment(store.month).isSame(selectedDate, 'month') && !isProjectManager) {
            store.changeMonthPopUp = true;
            store.selectedDate = selectedDate.toDate();
            return;
        }
        store.changeMonthDecision = false;
        store.month = selectedDate.toDate();
    };

    return (
        <Grid item xs={12}>
            <ChangeMonthPopUp store={store} />
            <InputLabel className={classes.label}>{t("suppliers.supplierHome.selectMonthAndYear")} </InputLabel>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                libInstance={moment}
                adapterLocale={he}
            >
                <MobileDatePicker
                    defaultValue={defalutDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    views={["year", "month"]}
                    value={store.month || defalutDate}
                    onChange={handleDateChange}
                    slotProps={{
                        toolbar: {
                          hidden: true,
                        },
                        textField: { variant: 'standard' }
                      }}
                />
            </LocalizationProvider>
        </Grid>
    )
});