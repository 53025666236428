import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Card, CardHeader, CardContent, Divider } from "@mui/material";
import WaveChart from "common/ui/waveChart";
import { t } from "common/localization/translate";
import ChangePeriodDropDown from "./components/changePeriodDropDown";
import { useScreenHomeStore } from "../../store";
import { LoadingSpinner } from "common/ui/loadingSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    height: 500,
  },
  header: { flexDirection: "row", display: "flex" },
  buttons: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 48,
    width: 48,
    display: "flex",
    justifyContent: "center",
  },
}));

const OpenAppCountsChart = observer((props) => {
  const classes = useStyles();
  const {
    formattedAppOpenData,
    isFetchingForSmallSpinner: isFetching,
  } = useScreenHomeStore();
  if (!formattedAppOpenData) {
    return null;
  }

  const counts = formattedAppOpenData.map((item) => item.count);
  const countsDistinct = formattedAppOpenData.map((item) => item.distinctCount);
  const labels = formattedAppOpenData.map((item) => item.date);

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        action={<ChangePeriodDropDown />}
        title={t("screenHome.openAppCountsChart.title")}
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <LoadingSpinner isFetching={isFetching} className={classes.chart}>
              <WaveChart
                className={classes.chart}
                labels={labels}
                linesData={[
                  { dataArray: counts, textLabel: t("screenHome.openAppCountsChart.labelValueType") },
                  { dataArray: countsDistinct, textLabel: t("screenHome.openAppCountsChart.labelValueTypeDistinct") }
                ]}
              />
            </LoadingSpinner>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
});

export default OpenAppCountsChart;
