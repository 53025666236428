import { t } from "common/localization/translate";
import { getCurrentPath } from "utils/direction/getCurrentPath";

export function getNotificationNavigationsList() {
    const { isOrganizationPath } = getCurrentPath();
    if (isOrganizationPath) {
        return [
            { text: t("sendNotificationScreen.navigationList.competition"), value: 'Competitions' },
            { text: t("sendNotificationScreen.navigationList.mindfulness"), value: 'MindfulnessStart' },
            { text: t("sendNotificationScreen.navigationList.history"), value: 'History' },
            { text: t("sendNotificationScreen.navigationList.myGroups"), value: 'GroupsYuvitalHealth' },
            { text: t("sendNotificationScreen.navigationList.virtualTrainer"), value: 'VirtualTrainingInfoYuvitalHealth' },
            { text: t("sendNotificationScreen.navigationList.articles"), value: 'Articles' },
            { text: t("sendNotificationScreen.navigationList.homeScreen"), value: 'HOME' }
        ];
    }

    return [
        { text: t("sendNotToUserSceern.navigationList.homeScreen"), value: 'HOME' },
        { text: t("sendNotToUserSceern.navigationList.articlesScreen"), value: 'ARTICLES' },
        { text: t("sendNotToUserSceern.navigationList.externalLink"), value: 'OPEN_URL' },
        { text: t("sendNotToUserSceern.navigationList.activitiesScreen"), value: 'ACTIVITIES' },
        { text: t("sendNotToUserSceern.navigationList.contentWorld"), value: 'CONTENT_OPTIONS' }
    ];
}