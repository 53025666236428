const ID_NUMBER_MAX_LENGTH = 11;

export const addZerosAsPrefixToShort = (idNumber) => {
    let prefix = '';
    const numberOfZerosToAddAsPrefix = ID_NUMBER_MAX_LENGTH - idNumber.length;
    for(let i = 0; i < numberOfZerosToAddAsPrefix; i++) {
        prefix += '0';
    }
    const idNumberWithZeros = prefix + idNumber;
    return idNumberWithZeros;
}