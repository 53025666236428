import { t } from "common/localization/translate";
import { formatNumber } from "common/formatNumber";

export const bonusTypes = {
  ARTICLES: "ARTICLES",
  REFERRAL: "REFERRAL",
  BMI: "BMI",
};

export function getBonusesForChart(store) {
  const { segmentUsersUsingBonusesTotals, segmentUsersUsingMeasurements } = store;
  if(segmentUsersUsingMeasurements){
    return;
  }
  if (!segmentUsersUsingBonusesTotals) {
    return [];
  }
  const isWithBMI = Number(segmentUsersUsingBonusesTotals.bmiBonusCount);
  const { segmentThemeColor } = store.rootStore.userInfoStore;

  let arrayOfBonusesForChart = [];
  arrayOfBonusesForChart.push({
    id: bonusTypes.ARTICLES,
    label: t("screenHome.bonusesTotals.articles"),
    value: segmentUsersUsingBonusesTotals.articlesBonusCount,
    valueExplaind: t("screenHome.bonusesTotals.articles.dataText", {
      value: formatNumber(segmentUsersUsingBonusesTotals.articlesBonusCount),
    }),
    color: isWithBMI ? segmentThemeColor.mainColor : segmentThemeColor.secondGraphColor,
  });
  arrayOfBonusesForChart.push({
    id: bonusTypes.REFERRAL,
    label: t("screenHome.bonusesTotals.referral"),
    value: segmentUsersUsingBonusesTotals.referralBonusCount,
    valueExplaind: t("screenHome.bonusesTotals.referral.dataText", {
      value: formatNumber(segmentUsersUsingBonusesTotals.referralBonusCount),
    }),
    color: segmentThemeColor.lightColor,
  });
  if (isWithBMI) {
    arrayOfBonusesForChart.push({
      id: bonusTypes.BMI,
      label: t("screenHome.bonusesTotals.bmi"),
      value: segmentUsersUsingBonusesTotals.bmiBonusCount,
      valueExplaind: t("screenHome.bonusesTotals.bmi.dataText", {
        value: formatNumber(segmentUsersUsingBonusesTotals.bmiBonusCount),
      }),
      color: segmentThemeColor.darkColor,
    });
  }

  return arrayOfBonusesForChart;
}
