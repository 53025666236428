import { verifyCouponCodesErrorCodes, verifyCouponCodesErrorTexts } from "./verifyCouponCodesError";

export const getVerifyCouponCodesErrorText = (errorCode) => {
    switch (errorCode) {
        case verifyCouponCodesErrorCodes.ERROR_NO_CODES_WERE_ENTERED_BY_SUPPLIER:
            return verifyCouponCodesErrorTexts.noErrorCodeWereEntered;
        case verifyCouponCodesErrorCodes.ERROR_NO_PRICE_FOR_COUPON:
            return verifyCouponCodesErrorTexts.codesAreNoValid;
        case verifyCouponCodesErrorCodes.ERROR_WHEN_UPDATE_VERIFIED_CODE_AS_IMPLEMENT_IN_DB:
            return verifyCouponCodesErrorTexts.codesAreNoValid;
    }
}