import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import SearchIcon from "@mui/icons-material/Search";
import { CommonButton } from "common/ui/commonButton";
import { useScreenCouponsStore } from "screens/support/coupons/store";

export const SubmitButton = observer(() => {
  const store = useScreenCouponsStore();
  const { isFetching } = store;

  return (
    <Grid item sm={4} lg={3} spacing={2}>
      <CommonButton disabled={isFetching}>
        <SearchIcon />
      </CommonButton>
    </Grid>
  );
});
