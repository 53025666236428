import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { CardHeader, Divider, Avatar } from "@mui/material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { t } from "common/localization/translate";


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5),
    height: 500
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 40,
    marginLeft: theme.spacing(7)
  }
}));

export const MeasurementsChartsHeader = observer(() => {
  const classes = useStyles();
  return (
      <div>
      <CardHeader
        action={
          <Avatar className={classes.avatar} size="small">
            <AssessmentOutlinedIcon />
          </Avatar>
        }
        title={t("screenHome.meausermentsTotal.title")}
      />
      <Divider />
      </div>
  );
});


