import EditIcon from '@mui/icons-material/Edit';
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import RedeemIcon from '@mui/icons-material/Redeem';
import BarChartIcon from '@mui/icons-material/BarChart';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Check from '@mui/icons-material/Check';

export default [
  {
    title: t("sideBar.menu.title"),
    pages: [
      {
        title: t("sideBar.benefits.title"),
        href: `/${dashboardPaths.SUPPORT}/getUserCoupons`,
        icon: RedeemIcon,
      },
      {
        title: t("sideBar.userBalance.title"),
        href: `/${dashboardPaths.SUPPORT}/userBalance`,
        icon: BarChartIcon
      },
      {
        title: t("sideBar.support.editUserInfo"),
        href: `/${dashboardPaths.SUPPORT}/editUserInfo`,
        icon: EditIcon,
      },
      {
        title: t("screenEntitlementData.entitlementSideBarTitle"),
        href: `/${dashboardPaths.SUPPORT}/getEntitledUserData`,
        icon: Check,
      },
      {
        title: t("sideBar.support.dailyStepsAndGoals"),
        href: `/${dashboardPaths.SUPPORT}/dailyStepsAndGoals`,
        icon: DirectionsRunIcon,
      },
      {
        title: t("sideBar.support.orderDetails"),
        href: `/${dashboardPaths.SUPPORT}/orderDetails`,
        icon: ShoppingBasketIcon,
      },
      {
        title: t("sideBar.support.sendNotificationToUser"),
        href: `/${dashboardPaths.SUPPORT}/sendNotificationToUser`,
        icon: NotificationsActiveIcon
      },
      {
        title: t("sideBar.support.confirmHideCoupons"),
        href: `/${dashboardPaths.SUPPORT}/getCouponsSubmittedForHide`,
        icon: LibraryAddCheckIcon
      },
    ],
  },
];
