import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenEditUserInfoStore } from "../../../store/index";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

export const EmailInput = observer((props) => {
  const store = useScreenEditUserInfoStore();

  const handleEmailChange = (event) => {
    const { value } = event.target;
    store.setUserEmail(value);
  };

  const isError = !store.userEmail;
  const errorText = !store.userEmail ? t("screenEditUserInfo.errors.emptyEmail") : null;

  return (
    <Grid item md={4} xs={12}>
    <TextField
      fullWidth
      label={t("screenEditUserInfo.emailAdress")}
      name="email"
      onChange={handleEmailChange}
      required
      value={store.userEmail}
      variant="outlined"
      error={isError}
      helperText={errorText}
    />
  </Grid>
  );
});
