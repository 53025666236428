import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { SubmitButton } from "./components/submitButton";
import { IdNumInput } from "./components/idNumInput";
import { useScreenDailyStepsAndGoalsStore } from "../../store";

const useStyles = makeStyles((theme) => ({
  row: {
    width: "100%",
    display: "flex",
    marginLeft: 0.2
  },
}));

export const IdNumForm = observer(() => {
  const store = useScreenDailyStepsAndGoalsStore();
  const classes = useStyles();

  const onSubmit = (e) => {
    e.preventDefault();
    store.submitIdentifier();
  };

  return (
    <form className={classes.row} onSubmit={onSubmit}>
      <Grid className={classes.row} container lg={12} xs={12} spacing={1}>
        <IdNumInput />
        <SubmitButton />
      </Grid>
    </form>
  );
});
