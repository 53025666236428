import { getGroupsNumber } from './getGroupsNumber'
import { t } from "common/localization/translate";
export function getColumnsWithGroups({columns,staffList}) {
   if(!staffList){
       return;
   }
   const groupsNumber = getGroupsNumber(staffList);
   const columnsWithGroups =  [...columns];
   for(let groupNumber=1; groupNumber<=groupsNumber; groupNumber++){
    columnsWithGroups.push(
        {
            name: "groupName",
            label: t("screenStaffInfo.table.col.groupName", {groupNumber})
        }
    )
   }
   return columnsWithGroups;
}