import {errorCodes} from './errorCodes'
export const validateIdNum = (idNum) => {
    let errorCode = null;
    if(!idNum){
        return errorCodes.ERROR_TYPE_ID_TOO_SHORT;
    }
    const userIdNum = Number(idNum.replace(/\s/g, '')).toString();
    const onlyNumbers = (/^\d+$/.test(userIdNum));
    if (userIdNum.length < 6) {
        errorCode = errorCodes.ERROR_TYPE_ID_TOO_SHORT;
    }
    if (!onlyNumbers) {
        errorCode = errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS;
    }
    return errorCode;
}