import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { t } from "common/localization/translate";
import { useScreenCouponsInInventoryStore } from "../store";
import { getIsRtl } from "utils/direction/getIsRtl";

const useStyles = makeStyles(theme => ({
  searchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: (props) => (props.isRtl ? 20 : 40),
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

export const ShowNowInInventoryButton = () => {
  const isRtl = getIsRtl();
  const classes = useStyles({ isRtl });

  const { getScreenData } = useScreenCouponsInInventoryStore();

  const handlePressCurrentInventory = () => {
    getScreenData(true);
  };

  return (
    <Button
      className={classes.searchButton}
      onClick={handlePressCurrentInventory}
      size="large"
      variant="contained"
    >
      {t("screenCouponsInInventory.button.allInInventory")}
    </Button>
  );
}