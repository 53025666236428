import React from "react";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useScreenEditUserInfoStore } from "../../../store";
import { GetErrorTextByErrorCode } from "../../../store/logic/getErrorTextByErrorCode";

const useStyles = makeStyles((theme) => ({
  errorText: {
    position: "absolute",
    marginTop: "60px",
    fontSize: "17px",
    fontFamily: "sans-serif",
    color: "red",
  },
}));

export const ErrorText = observer((props) => {
  const store = useScreenEditUserInfoStore();
  const classes = useStyles();
  const text = GetErrorTextByErrorCode(store.errorCode);

  return <p className={classes.errorText}>{text}</p>;
});
