import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.icon,
      minWidth: '32px'
    }
  }
};
