import Excel from "exceljs/dist/exceljs";
import { createSheet } from "./createSheet";
import { downloadWorksheet } from "../downloadWorksheet";
import { setSheetProps } from "../setSheetProps";
import { t } from "common/localization/translate";

const START = 'start';
const FINISH = 'finish';
const COL_1_WIDTH = 10;
const OTHER_COLS_WIDTH = 20;
const TOTAL_COLUMNS = 11;

export async function exportAllPoliceDataToExcel(fitnessData) {
    const workbook = new Excel.Workbook();
    const sheetStart = workbook.addWorksheet(t("screenHealthAndFitnessData.data.start"));
    const sheetFinish = workbook.addWorksheet(t("screenHealthAndFitnessData.data.finish"));

    setSheetProps({ sheets: [sheetStart, sheetFinish], firstColWidth: COL_1_WIDTH, otherColsWidth: OTHER_COLS_WIDTH, totalCols: TOTAL_COLUMNS });
    createSheet(sheetStart, fitnessData, t("screenHealthAndFitnessData.data.start"), START);
    createSheet(sheetFinish, fitnessData, t("screenHealthAndFitnessData.data.finish"), FINISH);
    downloadWorksheet(workbook, t("screenHealthAndFitnessData.excel.filename.allCops"));
}