import React from "react";
import { observer } from "mobx-react";
import MUIDataTable from "mui-datatables";
import { t } from "common/localization/translate";
import { useScreenPurchasesStore } from "../store";
import { getTableDataOptions } from "../store/logic/getTableDataOptions";

export const PurchasesTable = observer(() => {
  const store = useScreenPurchasesStore();
  const { purchasesTableData, isFetching } = store;
  const isWithSegmentName = purchasesTableData && purchasesTableData.length > 0 && purchasesTableData[0].length > 3;
  const { title, options, columns } = getTableData(isFetching, isWithSegmentName);

  return (
    <MUIDataTable
      title={title}
      data={purchasesTableData}
      columns={columns}
      options={options}
    />
  );
});

const getTableData = (isFetching, isWithSegmentName) => {
  const tableDataOptions = getTableDataOptions();
  const columns = [
    { name: "title", label: t("screenPurchases.table.col.couponTitle"), options: tableDataOptions },
    {
      name: "businessName",
      label: t("screenPurchases.table.col.businessName"),
      options: tableDataOptions
    },
    { name: "count", label: t("screenPurchases.table.col.couponCount"), options: tableDataOptions }
  ];
  if (isWithSegmentName) {
    columns.push({ name: "segmentName", label: t("screenPurchases.table.col.segmentName"), options: tableDataOptions })
  }

  const options = {
    filter: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    downloadOptions: { filename: "purchases.csv" },
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    sortDirection: "desc",
    textLabels: {
      body: {
        noMatch: isFetching ? "Loading..." : "Sorry, no matching records found",
      },
    },
  };
  const title = t("screenPurchases.table.title");
  return { title, options, columns };
};
