import React, { useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button, ButtonGroup } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { he, enUS } from "date-fns/locale";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  getNowInMoment,
  subtractFromDate,
  convertDateToMoment
} from "common/dateAndTime/moment";
import "moment/locale/he";
import { getIsRtl } from "utils/direction/getIsRtl";

const useStyles = makeStyles(theme => ({
  root: {},
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginTop: 10
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  buttonGroup: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    display: 'flex',
    flexDirection: props => props.isRtl ? "row" : "row-reverse",
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    },
    borderLeft: `1px solid #243a7e`,
    borderRadius: '2px',
  },
  datePicker: { display: 'none' }
}));

const DatePicker = props => {
  const { className, ...rest } = props;
  const isRtl = getIsRtl();
  const classes = useStyles({ isRtl });
  const now = getNowInMoment();
  const seventDaysAgo = subtractFromDate(now, 7, "days");
  const [startDate, setStartDate] = useState(seventDaysAgo);
  const [endDate, setEndDate] = useState(now);
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(now);

  const handleCalendarOpen = async (edge)=> {
    if (edge === "start") {
      await setCalendarDate(startDate);
    } else {
      await setCalendarDate(endDate);
    }
    setSelectEdge(edge);

  };

  const handleCalendarChange = date => {
    date = moment(date)
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    const now = getNowInMoment();
    setCalendarDate(now);

    setSelectEdge(null);
  };

  const handleCalendarAccept = date => {
    date = moment(date)
    const { onChangeStartDate, onChangeEndDate } = props;
    const now = getNowInMoment();
    const dateInMoment = convertDateToMoment(date);
    setCalendarDate(now);

    if (selectEdge === "start") {
      setStartDate(date);
      onChangeStartDate(date);

      if (dateInMoment.isAfter(endDate)) {
        setEndDate(date);
        onChangeEndDate(date);
      }
    } else {
      setEndDate(date);
      onChangeEndDate(date);

      if (dateInMoment.isBefore(startDate)) {
        setStartDate(date);
        onChangeStartDate(date);
      }
    }

    setSelectEdge(null);
  };

  const open = Boolean(selectEdge);
  moment.locale("he");

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        libInstance={moment}
        adapterLocale={isRtl ? he : enUS}
      >
      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid className={classes.dates} item lg={6} xs={12}>
          <ButtonGroup variant="contained" className={classes.buttonGroup}>
            <Button
              className={classes.button}
              onClick={() => handleCalendarOpen("end")}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {endDate.format("DD/MM/YYYY")}
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleCalendarOpen("start")}
            >
              <CalendarTodayIcon className={classes.calendarTodayIcon} />
              {startDate.format("DD/MM/YYYY")}
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
        <MobileDatePicker
          maxDate={now}
          onAccept={handleCalendarAccept}
          onChange={handleCalendarChange}
          onClose={handleCalendarClose}
          disableFuture={true}
          open={open}
          className={classes.datePicker}
          value={calendarDate}
          slotProps={{
            toolbar: {
              hidden: true,
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
