import { colors } from '@mui/material';

import palette from '../palette';

export default {
  styleOverrides: {
    root: {
      color: palette.icon,
      '&:hover': {
        backgroundColor: colors.grey[100]
      },
      '&$selected': {
        backgroundColor: colors.grey[50],
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: colors.grey[100]
        }
      },
      '&:first-child': {
        borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px'
    },
    '&:last-child': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    }
  }
}
};
