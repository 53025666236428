import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenSendNotificationStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from 'common/localization/translate';


const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        fontSize: 16
    },
    content: {
        fontFamily: 'Assistant',
        fontSize: 14
    }, 
     button: {
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.secondGraphColor,
        },
      }
}));


export const SuccessSendNotificationPopUp = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();
    
    const handleClose = () => {
        store.isSuccessSendNotification = false;
    };


    return (
        <div>
            <Dialog open={store.isSuccessSendNotification}>
                <DialogTitle className={classes.title}>{t("support.sendNotificationScreen.successSendNotificationTitlie")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("support.sendNotificationScreen.successSendNotificationContent")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={classes.button} autoFocus >{t("support.sendNotificationScreen.successSendNotificationButton")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})