import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenHomeStore, ScreenHomeStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { Header } from "./components/header";
import { GeneralTotalNumbersCubesRow } from "./components/totalNumbersCubesRow";
import TopRefferalsTable from "./components/topReferralsTable";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import NewUsersChart from "./components/newUsersChart";
import BonusesChart from "./components/bonusesChart";
import MeasurmentsChart from "./components/measurementsChart";
import OpenAppCountsChart from "./components/openAppCountsChart";
import ActiveUsersChart from "./components/activeUsersChart";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(1.5),
    },
    row: {
      height: "100%",
    },
  },
}));

export const Home = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenHomeStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
    store.getScreenData();
  }, []);

  const { isFetching, arrayOfMeausermentsForChart } = store;
  const pieChart = arrayOfMeausermentsForChart ? (
    <MeasurmentsChart />
  ) : (
    <BonusesChart />
  );

  return (
    <ScreenHomeStoreProvider value={store}>
      <LoadingSpinner isFetching={isFetching}>
        <>
          <Page className={classes.root} title={t("screenHome.overview.title")}>
            <Header />
            <Grid className={classes.container} container spacing={3}>
              <GeneralTotalNumbersCubesRow />
            </Grid>
            <Grid className={classes.container} container spacing={3}>
              <Grid item lg={8} xl={9} xs={12} className={classes.row}>
                <OpenAppCountsChart />
              </Grid>
              <Grid item lg={4} xl={3} xs={12} className={classes.row}>
                {pieChart}
              </Grid>
            </Grid>
            <Grid className={classes.container} container spacing={3}>
              <Grid item lg={8} xl={9} xs={12} className={classes.row}>
                <NewUsersChart />
              </Grid>
              <Grid item lg={4} xl={3} xs={12} className={classes.row}>
                <TopRefferalsTable />
              </Grid>
              <Grid item lg={12} xl={12} xs={12} className={classes.row}>
                <ActiveUsersChart />
              </Grid>
            </Grid>
          </Page>
        </>
      </LoadingSpinner>
    </ScreenHomeStoreProvider>
  );
});
