import { errorCodes } from "./orderDetailsErrorCodes";

export const validateOrderId = (orderId) => {
  if (!orderId) {
    return errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS;
  }
  const onlyNumbers = /^\d+$/.test(orderId);
  if (!onlyNumbers) {
    return errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS;
  }
  return null;
};
