import React from "react";
import { t } from "common/localization/translate";
import { useScreenEntitlementStatusStore } from "../../store";
import { observer } from "mobx-react";
import { StatusText } from "../statusText";

export const UnderageStatus = observer(() => {
    const store = useScreenEntitlementStatusStore();
    const descriptionText = t("screenEntitlementData.underage");
    const entitlementStatusText = store.isUnderage ? t("screenEntitlementData.underage.yes") : t("screenEntitlementData.underage.no");

    return (
        <StatusText descriptionText={descriptionText} statusText={entitlementStatusText} isPositive={store.isUnderage} />
    );
});
