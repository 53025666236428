import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import Textarea from '@mui/material/TextareaAutosize';
import { LoadingCodesInputErrors } from './loadingCodesInputErrors';

export const LoadingCodesInput = observer((props) => {
    const { store } = props;

    const handleText = (e) => {
        store.codes = e.target.value;
    }

    return (
        <Grid item xs={12} spacing={3}>
            <Textarea id='codes' error={true} minRows={5} rowsMax={6} cols="40" rows="5" placeholder={t("suppliers.supplierHome.insertCodesPlaceHolder")} onChange={handleText} />
            <LoadingCodesInputErrors />
        </Grid>
    )
});

