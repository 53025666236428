import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import { t } from "common/localization/translate";
import { IdNumForm } from "./components/idNumForm";
import { createScreenDailyStepsAndGoalsStore, ScreenDailyStepsAndGoalsProvider } from "./store";
import { DailyStepsAndGoalsTable } from "./components/DailyStepsAndGoalsTable";
import { PageTitles } from "./components/PageTitles";
import { Redirect } from "react-router-dom";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  }
}));

export const DailyStepsAndGoals = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenDailyStepsAndGoalsStore(rootStore));
  const classes = useStyles();
  const { userDailyStepsAndGoalsTableData } = store;

  if (!rootStore.userInfoStore.isShowStepsAndGoalsScreen) {
    return (
      <Redirect to={{ pathname: `/${dashboardPaths.SUPPORT}/home` }} />
    );
  }

  return (
    <ScreenDailyStepsAndGoalsProvider value={store}>
      <Page className = {classes.root} title={t("screenDailyStepsAndGoals.title")}>
      <PageTitles classes={classes} />
          <IdNumForm />
        <DailyStepsAndGoalsTable DailyStepsAndGoalsTableData = { userDailyStepsAndGoalsTableData } />
      </Page>
    </ScreenDailyStepsAndGoalsProvider>
  );
});
