import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";

const useStyles = makeStyles(theme => ({
  row: {
    width: "100%",
    paddingLeft: 10
  },
  img: {
    height: 35
  }
}));

export const WeeklyImprovementPercentageCube = observer(() => {
  const classes = useStyles();
  const {
    weeklyImprovement,
    isFetchingSegmentImprovement,
    selectedSegmentName
  } = useScreenHomeStore();

  if (!weeklyImprovement) {
    return null;
  }

  const improvementPercentage = weeklyImprovement || 0;
  const segmentImprovementValueText =
    improvementPercentage >= 0
      ? t("screenHome.totalNumbersCubesRow.avgImprovement.display", {
        number: improvementPercentage
      })
      : t("screenHome.totalNumbersCubesRow.avgImprovement.Minusdisplay", {
        number: improvementPercentage * -1
      });


  return (
    <Grid item xs={12} md={6} lg className={classes.row}>
      <SimpleCube
        isReversed={false}
        isFetching={isFetchingSegmentImprovement}
        label={t("screenHome.totalNumbersCubesRow.avgImprovement.weekly", {
          selectedSegmentName
        })}
        value={segmentImprovementValueText}
        icon={<CallMadeIcon />}
        withoutMargin={true}
      />
    </Grid>
  );
});
