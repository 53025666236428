import React from "react";
import { observer, useObserver } from "mobx-react";
import { t } from "common/localization/translate";
import MUIDataTable from "mui-datatables";
import { useScreenConfirmHideCouponsStore } from "../store/index";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { getTableHeaders } from "../store/logic/getTableHeaders";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles(() => ({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const CouponsTable = observer(() => {
  const store = useScreenConfirmHideCouponsStore();
  const { userInfoStore } = useRootStore();
  const { dashboardFeatures } = userInfoStore;
  const classes = useStyles();

  return useObserver(() => {
    const isShowOrderIdForCouponsScreenSupportDashboard = dashboardFeatures && dashboardFeatures.isShowOrderIdForCouponsScreenSupportDashboard;
    const {
      couponsUserTableData,
      errorCode,
      setCurrentRow,
      isFetching
    } = store;
    const columns = getTableHeaders({ isShowOrderIdForCouponsScreenSupportDashboard });

    const onRowsSelect = (data) => {
      const { dataIndex: index } = data[0];
      setCurrentRow(index);
    };

    const tableOptions = { ...options, onRowsSelect };

    if (isFetching) {
      return (
        <div className={classes.spinnerContainer}>
          <CircularProgress color="primary" />
        </div>
      );
    }

    if (errorCode || couponsUserTableData.length == 0) {
      return null;
    }

    return (
      <div>
        <MUIDataTable
          title={t("screenConfirmHideCoupons.table.title")}
          data={couponsUserTableData}
          columns={columns}
          options={tableOptions}
        />
      </div>
    );
  })
});

const options = {
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  responsive: "scrollMaxHeight",
  selectableRows: "single",
  customToolbarSelect: () => { },
  onRowsDelete: () => false,
  textLabels: {
    body: {
      noMatch: t("screenCouponsUser.table.col.noMatch"),
    },
    selectedRows: {
      text: t("screenCouponsUser.table.chosen"),
      delete: "Delete",
      deleteAria: "Delete Selected Rows",
    },
  },
};
