export const tableDataOptions = {
    customBodyRender: (value) => <div style={{ textAlign: 'right' }}>{value}</div>,
    setCellHeaderProps: value => ({ style: { textAlign: 'right', padding: 0 } }),
    customHeadLabelRender: ({ index, ...column }) => <div style={{ color: "#546e7a", fontSize: "12px", fontWeight: 500 }} >{column.label}</div>
}

export const tableDataOptionsEnglish = {
    customBodyRender: (value) => <div style={{ textAlign: 'left' }}>{value}</div>,
    setCellHeaderProps: value => ({ style: { textAlign: 'left', padding: 0 } }),
    customHeadLabelRender: ({ index, ...column }) => <div style={{ color: "#546e7a", fontSize: "12px", fontWeight: 500, marginLeft: 15 }}>{column.label}</div>
}