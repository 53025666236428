import React from "react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { useScreenDailyStepsAndGoalsStore } from "screens/support/dailyStepsAndGoals/store";
import { GetDailyStepsAndGoalsErrorTextByErrorCode } from "screens/support/dailyStepsAndGoals/store/logic/getDailyStepsAndGoalsErrorTextByErrorCode";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "155px",
  },
}));

export const IdNumInput = observer((props) => {
  const rootStore = useRootStore();
  const store = useScreenDailyStepsAndGoalsStore();
  const placeHolder = rootStore.userInfoStore.isWithSearchByPhoneOption ? t("support.common.withPhone.placeHolder") : t("support.common.placeHolder");
  const inputProps = { maxLength: 10 };
  const classes = useStyles();
  const errorText = GetDailyStepsAndGoalsErrorTextByErrorCode(store.errorCode);

  const handleInputChange = (event) => {
    const { value } = event.target;
    store.userIdentifier = value;
  };
  const isError = store.errorCode ? true : false;

  return (
    <Grid item sm={4} lg={2} spacing={2}>
      <TextField
        className={classes.input}
        id="standard-basic"
        variant='standard'
        type="string"
        error={isError}
        placeholder={placeHolder}
        helperText={errorText}
        inputProps={inputProps}
        onChange={handleInputChange}
      />
    </Grid>
  );
});
