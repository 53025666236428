import { styleSheet } from "../styleSheet";
import { t } from 'common/localization/translate';
import { writeToRow } from "screens/organization/home/store/logic/createExcel/writeToRow";

export const START_DATA_ROW = 4;

export function createSheet(sheet, fitnessData, tableName, stageStr) {
    sheet.mergeCells('A2:K2');

    writeToRow(sheet, [tableName], 2);
    writeToRow(sheet, [
        t("screenHealthAndFitnessData.excel.counter"), 
        t("screenHealthAndFitnessData.personalDetails.name"), 
        t("screenHealthAndFitnessData.personalDetails.id"), 
        t("screenHealthAndFitnessData.excel.bloodPressure"), 
        t("screenHealthAndFitnessData.excel.weight"), 
        t("screenHealthAndFitnessData.excel.fatPercentage"), 
        t("screenHealthAndFitnessData.excel.waist"), 
        t("screenHealthAndFitnessData.excel.star"), 
        t("screenHealthAndFitnessData.excel.squat"), 
        t("screenHealthAndFitnessData.excel.pushups"), 
        t("screenHealthAndFitnessData.excel.rowing")
    ], 3);
    
    let rowNumber = START_DATA_ROW;
    fitnessData.forEach(item => {
        if (item[stageStr]) {
            const rowCounter = rowNumber - (START_DATA_ROW - 1);
            const { bloodPressure, weight, waistCircumference, fatPercentage, pushups, star, squat, rowing } = item[stageStr];
            writeToRow(sheet, [rowCounter, item.userInfo.name, item.userInfo.idNum, bloodPressure, weight, fatPercentage, waistCircumference, star, squat, pushups, rowing], rowNumber);
            rowNumber++;
        }
    });

    styleSheet(sheet, rowNumber, 'A2:K2');
}