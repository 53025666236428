import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import {
  createScreenMultiSegmentUsersStore,
  ScreenMultiSegmentUserssStoreProvider,
} from "./store";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { Redirect } from "react-router-dom";
import {MultiSegmentUsersTable} from './components/multiSegmentUsersTable'
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
    },
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginLeft: 15,
    fontSize: "25px",
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 1,
  },
  title: {
    fontSize: '25px',
    lineHeight: '18px',
    marginTop: 2,
  },
}));

export const MultiSegmentUsers = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenMultiSegmentUsersStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
    store.getScreenData();
  }, []);

  if(!rootStore.userInfoStore.isShowMultiSegmentUsersScreen){
    return  <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/home` }} />
  }
  return (
    <ScreenMultiSegmentUserssStoreProvider value={store}>
      <Page className={classes.root} title={ t("screenMultiSegmentUsers.table.title")}>
        <Grid className={classes.header} item lg={6} xs={12}>
          <Typography className={classes.title} component="h2" gutterBottom variant="overline">
            {t("screenMultiSegmentUsers.table.title")}
          </Typography>
          <Tooltip title={t("organizationDashboard.subSegmentUsers.tooltip")} classes={classes}>
              <HelpIcon className={classes.toolTipIcon} />
          </Tooltip>
        </Grid>
     <MultiSegmentUsersTable/>
      </Page>
    </ScreenMultiSegmentUserssStoreProvider>
  );
});



