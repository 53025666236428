import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../store";
import { t } from "common/localization/translate";
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
  toolTipIcon: {
    color: theme.palette.primary.main
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold'
  },
}));

export const RefundSupplier = observer((props) => {
  const { isProjectManager } = props;

  if (isProjectManager) {
    return null;
  }

  const store = useScreenHomeStore();
  const classes = useStyles();
  const handleRefund = (e) => {
    if (e.target.value < 0) {
      e.target.value = 0;
    }
    store.refund = e.target.value;
  }

  return (
    <Grid item xs={12} spacing={3}>
      <InputLabel className={classes.label} >{t("suppliers.supplierHome.insertRefund")}</InputLabel>
      <TextField type="number" error={false} onChange={handleRefund} helperText={''} variant='standard'/>
      <Tooltip title={t("suppliers.supplierHome.toolTipRefund")} >
        <HelpIcon className={classes.toolTipIcon} />
      </Tooltip>
    </Grid>
  )
});