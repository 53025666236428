import React from "react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";
import { useScreenSendNotificationStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
errorText:{
    fontSize:10,
    color:'red'
}
}));

export const NotificationTypeError = observer(() => {
    const store = useScreenSendNotificationStore();
    const classes = useStyles();
  
if(!store.isEmptyNotificationType){
    return null;
}

    return (
            <Grid item xs={12}>
                <Typography className = {classes.errorText} >{t("support.sendNotificationScreen.emptyType")}</Typography>
            </Grid>
    )
});

