import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { CouponTypeDropDown } from './couponTypeDropDown';
import { SelectDate } from './selectDate';
import { SuccessFinishedEnterCodesPopUp } from '../supplier/successFinishedEnterCodesPopUp';
import { LoadingCodesButton } from './loadingCodesSupplier/loadingCodesButtons';
import { RefundSupplier } from './refundSupplier';
import { LoadingCodesInput } from './loadingCodesSupplier/loadingCodesInput';
import { FinishLoadCodes } from './loadingCodesSupplier/finishLoadCodes';
import { SuppliersDropDown } from "../projectManager/monthlySupplierReport/suppliersDropDown";

export const SupplierCodes = observer((props) => {
    const { isProjectManager, store } = props; 

    if (store.changeMonthDecision) {
        return null;
    }

    return (
        <Grid container spacing={3}>
            <SuccessFinishedEnterCodesPopUp store={store}/>
            <SelectDate store={store} isProjectManager={isProjectManager}/>
            <SuppliersDropDown store={store} isGetCoupons={true}/>
            <CouponTypeDropDown store={store}/>
            <LoadingCodesInput store={store}/>
            <RefundSupplier isProjectManager={isProjectManager}/>
            <LoadingCodesButton store ={store}/>
            <FinishLoadCodes isProjectManager={isProjectManager}/>
        </Grid>
    )
});

