import { getCurrentPath } from "./getCurrentPath";

export function getDirectionFromUrl() {
    const { isOrganizationPath } = getCurrentPath();

    if (isOrganizationPath) {
        return "ltr";
    }

    return "rtl";
}