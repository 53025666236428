import React from "react";
import { observer } from "mobx-react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext";

export const UserIdNum = observer(() => {
    const store = useHealthAndFitnessDataStore();

    return (
        <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label={t("screenHealthAndFitnessData.personalDetails.id")}
                name={"userIdNum"}
                value={store.userIdNum}
                variant="outlined"
                disabled
            />
        </Grid>
    );
});