export function createTraineesInfoTableDataArray(traineesList) {
    return traineesList.map(item => createDefaultDataArray(item));
}

const createDefaultDataArray = item => {
    const { name, identifier, phone, email, registrationDate, lastOpenDate, stepsAvg, appOpens, articleReading, videosConsumption, lastContentConsumption } = item || {};
    return [
        name || '---',
        identifier || '---',
        phone || '---',
        email || '---',
        registrationDate || '---',
        lastOpenDate || '---',
        stepsAvg || '---',
        appOpens || '---',
        articleReading || '---',
        videosConsumption || '---',
        lastContentConsumption || '---'
    ];
}