import { t } from "common/localization/translate";
import { policeErrorCodes } from "./policeErrorCodes";

export function getErrorTextByErrorCode(errorCode) {
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case policeErrorCodes.NO_SEGMENT_SELECTED:
            return t("screenHealthAndFitnessData.error.noSegment");
        case policeErrorCodes.ERROR_TYPE_NO_INPUT_DATA:
            return t("screenHealthAndFitnessData.error.noData");
        case policeErrorCodes.ERROR_TYPE_USER_NOT_EXISTS:
            return t("screenCouponsUser.errors.userNotExsist");
        case policeErrorCodes.ERROR_TYPE_INVALID_DATA:
            return t("screenCouponsUser.errors.invalidInput");
        case policeErrorCodes.ERROR_TYPE_DATA_NOT_SAVED:
            return t("screenHealthAndFitnessData.error.dataNotSaved");
        case policeErrorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
            return t("screenCouponsUser.errors.limitReached");
        case policeErrorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return t("screenHealthAndFitnessData.error.onlyNumbers");
        case policeErrorCodes.ERROR_TYPE_ID_TOO_SHORT:
            return t("screenCouponsUser.errors.idNumberTooShort");
        default:
            return t("screenCouponsUser.errors.userNotExsist");
    }
}
