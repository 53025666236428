import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenHomeStore } from "../../store";
import { makeStyles } from "@mui/styles";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        "& h6": {
            color: theme.palette.white,
            fontSize: 16,
            fontFamily: 'Assistant',
            fontWeight: 'bold'
        }
    },
}));

export const AreYouSureFinishLoadingCodesPopUp = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();
    

    const handleClose = () => {
        store.isAreYouSureFinishLoadCodesPopUp = false;
    };

    const handleCompliteLoadCodes = () =>{
        store.isAreYouSureFinishLoadCodesPopUp = false;
        store.finishEnteringCodes();
    }

    return (
        <div>
            <Dialog open={store.isAreYouSureFinishLoadCodesPopUp} >
                <DialogTitle className={classes.title} >{t("suppliers.supplierHome.completeEnterCodesPopUp")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("suppliers.supplierHome.areYouSureYouCompliteEnterCodesPopUp")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCompliteLoadCodes} color="primary" autoFocus >{t("suppliers.supplierHome.areYouSureYouCompliteEnterCodesPopUpYes")}</Button>
                    <Button onClick={handleClose} color="primary" autoFocus >{t("suppliers.supplierHome.areYouSureYouCompliteEnterCodesPopUpNo")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})