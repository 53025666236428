import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Divider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { OtpData } from "./otpData";
import { EntitlementData } from "./entitlementData";
import { HorizontalSpace } from "screens/organization/healthAndFitnessData/components/idNumForm/components/horizontalSpace";
import { EntitlementHistoryTable } from "./entitlementHistory";
import { UnderageInfo } from "./underageInfo";
import { useScreenEntitlementStatusStore } from "../store";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        flex: 1,
        width: "70%",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10
    }
}));

export const EntitlementInfo = observer(() => {
    const store = useScreenEntitlementStatusStore();
    const classes = useStyles();

    if (!store.userFound) {
        return null;
    }

    return (
        <Grid>
            <Grid className={classes.container}>
                <EntitlementData />
                <HorizontalSpace widthSize={4} />
                <OtpData />
            </Grid>
            <Divider />
            <EntitlementHistoryTable />
            <UnderageInfo />
        </Grid>
    );
});
