import { addZerosAsPrefixToShort } from "./addZerosAsPrefixToShort";
import { checkIfIdNumberHasValidStructure } from "./checkIfIdNumberHasValidStructure";
import { isPatternOfDigitsAndNotZerosOnly } from "./isPatternOfDigitsAndNotZerosOnly";

export function validateIsraeliIdStructure(idNumber) {
  if(!idNumber) {
    return false;
  }
  idNumber = idNumber.trim();
  if(!isPatternOfDigitsAndNotZerosOnly(idNumber) || idNumber.length > 11) {
    return false;
  }
  if(idNumber.length < 11) {
    idNumber = addZerosAsPrefixToShort(idNumber);
  }
  const isIdNumberHasValidStructure = checkIfIdNumberHasValidStructure(idNumber);
  return isIdNumberHasValidStructure;
}