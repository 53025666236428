import { alpha } from '@mui/material/styles';

export function getBottomGradient({ canvas, theme }) {
    const context = canvas.chart.ctx;
    const bottomGradient = context.createLinearGradient(0, 0, 0, 310);
    bottomGradient.addColorStop(1, alpha(theme.palette.white, 0));
    bottomGradient.addColorStop(0.8, alpha(theme.palette.primary.secondGraphColor, 0.9));
    bottomGradient.addColorStop(0, theme.palette.white);
    return {
        target: "origin",
        above: bottomGradient
    }
}