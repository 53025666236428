import React, { useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { createScreenHealthAndFitnessDataStore } from './store';
import { HealthAndFitnessDataStoreProvider } from './store/storeHealthAndFitnessDataStoreContext';
import { IdNumForm } from './components/idNumForm';
import { IdNumSearchHeader } from './components/idNumSearchHeader';
import { MeasurementsData } from './components/measurementsData';
import { Page } from "components";
import { PersonalInfo } from './components/personalInfo';
import { SearchResultHeader } from './components/searchResultHeader';
import { StageDataCards } from './components/stageDataCardsAndExport';
import { t } from "common/localization/translate";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { LoadingCopDataSpinner } from './components/loadingCopDataSpinner';
import { Header } from './components/header';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3)
    }
}));

export const HealthAndFitnessData = observer(() => {
    const rootStore = useRootStore();
    const [store] = useState(() => createScreenHealthAndFitnessDataStore(rootStore));
    const classes = useStyles();

    useEffect(() => {
        store.getScreenData();
    }, [])

    const handleExportToExcel = () => {
        store.getAllDataAndExportToExcel();
    }

    return (
        <HealthAndFitnessDataStoreProvider value={store}>
            <Page className={classes.root} title={t("sideBar.healthAndFitnessData.title")}>
                <Header />
                <StageDataCards />
                {/* <ExportToExcel label={t("screenHealthAndFitnessData.data.export.allCops")} onClick={handleExportToExcel}/> */}
                <IdNumSearchHeader />
                <IdNumForm />
                <LoadingCopDataSpinner />
                <SearchResultHeader />
                <PersonalInfo />
                <MeasurementsData />
            </Page>
        </HealthAndFitnessDataStoreProvider>
    );
});