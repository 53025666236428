import React from "react";
import { t } from "common/localization/translate";
import { useScreenEntitlementStatusStore } from "../../store";
import { observer } from "mobx-react";
import { StatusText } from "../statusText";

export const EntitlementStatus = observer(() => {
    const store = useScreenEntitlementStatusStore();
    const descriptionText = t("screenEntitlementData.entitlementStatus");
    const entitlementStatusText = store.entitlementStatus ? t("screenEntitlementData.entitled") : t("screenEntitlementData.notEntitled");

    return (
        <StatusText descriptionText={descriptionText} statusText={entitlementStatusText} isPositive={store.entitlementStatus} />
    );
});
