import React from "react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import SearchIcon from "@mui/icons-material/Search";
import { CommonButton } from "common/ui/commonButton";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

const useStyles = makeStyles(() => ({
    text: {
        color: "#fff",
        marginRight: 5
    },
}));

export const SubmitButton = observer(() => {
    const classes = useStyles();
    const store = useHealthAndFitnessDataStore();
    const { isFetchingCopData, userIdNumInput } = store;

    return (
        <Grid item sm={4} lg={3} spacing={2}>
            <CommonButton disabled={isFetchingCopData || !userIdNumInput}>
                <Typography className={classes.text}>{t("screenHealthAndFitnessData.search.button")}</Typography>
                <SearchIcon />
            </CommonButton>
        </Grid>
    );
});