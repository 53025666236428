import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useScreenSendNotificationStore } from "../store/index";
import Grid from '@mui/material/Unstable_Grid2';
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold'
    }
}));

export const UserDetails = () => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();
    const { userName, userIdNum, isSendNotificationToAllUsers } = store;
    if (isSendNotificationToAllUsers) {
        return null;
    }

    return (
        <Grid>
            <Typography className={classes.title} gutterBottom> {t("support.sendNotificationScreen.userName", { userName })}</Typography>
            <Typography className={classes.title} gutterBottom> {t("support.sendNotificationScreen.idNum", { idNum: userIdNum })}</Typography>
        </Grid>
    );
}