import React from "react";
import { FormControlLabel } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from "@mui/material/Checkbox";
import { observer } from "mobx-react";
import { useBillingReportSystemStore } from "../../store/index"; 
import { t } from "common/localization/translate";

export const IsPerSupplierCheckbox = observer(() => {
  const store = useBillingReportSystemStore();

  const handleChange = (event) => {
    store.isPerSupplier = event.target.checked;
    if(!store.isPerSupplier){
        store.selectedSuppliers = [];
    }
  };

  return (
    <Grid item xs={12} spacing={3}>
      <FormControlLabel
        control={<Checkbox checked={store.isPerSupplier ? true : false} onChange={handleChange} />}
        label={t("suppliers.billingReportSystem.isPerSupplier")}
      />
    </Grid>
  );
});