import React from "react";
import { observer } from "mobx-react";
import MUIDataTable from "mui-datatables";
import { t } from "common/localization/translate";
import { useScreenStaffInfoStore } from "../store";
import { getStaffInfoTableData } from "../store/logic/getStaffInfoTableData";
import { getColumnsWithGroups } from '../store/logic/getColumnsWithGroups'

export const StaffTable = observer(() => {
  const store = useScreenStaffInfoStore();
  const { isFetching, staffList } = store;
  const { title, options, columns } = getTableData(isFetching);
  const data = getStaffInfoTableData(store);
  const columnsWithGroups = getColumnsWithGroups({columns, staffList});
  return (
      <MUIDataTable
        title={title}
        data={data}
        columns={columnsWithGroups}
        options={options}
      />
  );
});

const getTableData = (isFetching) => {
  const columns = [
    {
      name: "staffNameAtExternalSheetData",
      label: t("screenStaffInfo.table.col.name"),
    },
    {
      name: "phone",
      label: t("screenStaffInfo.table.col.phone"),
    },
    {
      name: "isSignedUp",
      label: t("screenTrainesInfo.table.col.conect"),
    },
    {
      name: "createdAt",
      label: t("screenTrainesInfo.table.col.registrationDate"),
    },
    {
      name: "lastOpen",
      label: t("screenTrainesInfo.table.col.lastLoginDate"),
    },
    {
      name: "email",
      label: t("screenTrainesInfo.table.col.email"),
    },
    {
      name: "userName",
      label: t("screenTrainesInfo.table.col.rumbleNickName"),
    },
    {
      name: "avgStepsLast30Days",
      label: t("screenTrainesInfo.table.col.avgStepsLast30Days"),
    },

    {
      name: "lastDepositDate",
      label: t("screenTrainesInfo.table.col.lastDepositDate"),
    },
    {
      name: "countAppOpenLast30Days",
      label: t("screenTrainesInfo.table.col.countAppOpenLast30Days"),
    },
    {
      name: "countPurchaseRumbleAcoSystemCoupons",
      label: t(
        "screenTrainesInfo.table.col.countPurchaseRumbleAcoSystemCoupons"
      ),
    },
    {
      name: "lastPurchaseAtidCouponDate",
      label: t("screenTrainesInfo.table.col.lastPurchaseAtidCouponDate"),
    },
    {
      name: "lastPurchaseAtidCouponName",
      label: t("screenTrainesInfo.table.col.lastPurchaseAtidCouponName"),
    },
    {
      name: "lastArticleReadDate",
      label: t("screenTrainesInfo.table.col.lastArticleReadDate"),
    },
    {
      name: "lastArticleReadName",
      label: t("screenTrainesInfo.table.col.lastArticleReadName"),
    },
    {
      name: "countReadAtricleInLast6Month",
      label: t("screenTrainesInfo.table.col.countReadAtricleInLast6Month"),
    }
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isFetching ? "Loading..." : "Sorry, no matching records found",
      },
    },
  };
  const title = t("screenStaffInfo.table.title");
  return { title, options, columns };
};
