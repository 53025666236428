import { t } from "common/localization/translate";
import { errorCodes } from './orderDetailsErrorCodes';

  

export const getOrderDetailsTextByErrorCode = (errorCode) => {
    if (!errorCode) {
        return;
    }
    switch (errorCode) {
        case errorCodes.ERROR_TYPE_ORDER_NOT_EXISTS:
            return t("screenOrderDetails.errors.orderNotExsist");
        case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
            return t("screenOrderDetails.errors.onlyNumbers");
        case errorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED:
            return t("screenOrderDetails.errors.limitReached");
        default:
            return t("screenOrderDetails.errors.orderNotExsist");
    }
};