import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondGraphColor,
    },
  },
}));

export const CommonButton = (props) => {
  const { className, onClick, children, disabled, size } = props;
  const classes = useStyles();

  return (
    <Button
      className={clsx(classes.button, className)}
      size={ size || "small" }
      type="submit"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
