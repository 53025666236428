export default {
    styleOverrides: {
        root:
        {
            '& div[class*="MUIDataTableToolbar-actions"]': { display: 'flex', justifyContent: 'end' },
            '& div[class*="MUIDataTableToolbar-left"]': {
                '& > *': {
                    '& > *': {
                        color: "#263238",
                        fontSize: "14px",
                        fontWeight: "500",
                    }
                }
            }

        }
    }
};