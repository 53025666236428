
import { t } from "common/localization/translate";

export const sendOtpCodeToProjectManagerErrorTexts = {
    mailSendFailed: t("suppliers.supplierHome.mailSendFailed"),
    sendOtpCodeLimiterExceeded: t("suppliers.supplierHome.sendOtpCodeLimiterExceeded"),
}

export const sendOtpCodeToProjectManagerErrorCodes = {
    MAIL_SEND_FAILED: "mailSendFailed",
    RATE_LIMITER_EXCEEDED: "rateLimiterExceeded",
}