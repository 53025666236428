import { makeStyles } from "@mui/styles";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { isPoliceSegment } from "../../../../screens/organization/purchaseUsersInfo/store/logic/isPoliceSegment";

const useStyles = makeStyles(() => ({
    police: {
        height: 90,
        padding: 10
    },
    default: {
        height: 45,
        marginTop: 10,
        width: 120
    },
}));

export function GetCustomTopBarLogoStyles(segmentId, isCustom) {
    const { userInfoStore } = useRootStore();
    const { customTopBarLogo } = userInfoStore;
    const classes = useStyles();
    // const defaultLogoUri =  "https://d3mspq0y1r4vba.cloudfront.net/poweredByYuvital.png";
    const defaultLogoUri =  null;
    
    const topBarLogo = {
        uri: defaultLogoUri,
        style: classes.default
    };

    if (!isCustom) {
        return topBarLogo;
    }

    topBarLogo.uri = customTopBarLogo;

    if (isPoliceSegment(segmentId)) {
        topBarLogo.style = classes.police;
    }
    return topBarLogo;
}