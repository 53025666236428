import { segmentsPaths } from "../../../../../commonStores/analytics/userInfoStore/logic/getSegmentPathById";

export function getUserDailyStepsAndGoalsTableData(store) {
  const segmentId = store.rootStore.userInfoStore.currentSegmentId;
  const { userDailyStepsAndGoals } = store;
  if (!userDailyStepsAndGoals) return [];

  if (
    segmentId == segmentsPaths.CLALIT.id ||
    segmentId == segmentsPaths.LEUMIT.id
  ) {
    return userDailyStepsAndGoals.map((item) => [
      item.day,
      item.dailySteps,
      item.dailyGoal,
      item.isDailyGoalMet,
      item.weeklySteps,
      item.weeklyGoal,
      item.isweeklyGoalMet,
    ]);
  }

  return userDailyStepsAndGoals.map((item) => [
    item.day,
    item.userDailySteps,
    item.userGoal,
    item.isGoalMet,
  ]);
}
