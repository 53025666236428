import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import {
  createScreenPurchasesStore,
  ScreenPurchasesStoreProvider,
} from "./store";
import { t } from "common/localization/translate";
import { PurchasesTable } from "./components/purchasesTable";
import { DatePicker } from "./components/datePicker";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { getIsRtl } from "utils/direction/getIsRtl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  header: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontSize: "25px",
    marginTop: 5,
  },
  row: {
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginLeft: (props) => (props.isRtl ? 0 : 15),
    marginRight: (props) => (props.isRtl ? 15 : 0),
    fontSize: "25px",
  },
  toolTipGrid: {
    marginRight: (props) => (props.isRtl ? 0 : "auto"),
    marginLeft: (props) => (props.isRtl ? "auto" : 0),
  },
}));

export const Purchases = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenPurchasesStore(rootStore));
  const isRtl = getIsRtl();
  const classes = useStyles({ isRtl });

  useEffect(() => {
    store.getScreenData();
  }, []);

  return (
    <ScreenPurchasesStoreProvider value={store}>
      <Page className={classes.root} title={t("screenPurchases.table.title")}>
        <Grid className={classes.header} item lg={6} xs={12}>
          <Typography
            className={classes.title}
            component="h2"
            gutterBottom
            variant="overline"
          >
            {t("screenPurchases.table.title")}
          </Typography>
          <Grid className={classes.toolTipGrid}>
            <Tooltip
              title={t("organizationDashboard.purchases.tooltip")}
              classes={classes}
            >
              <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container lg={12} sm={12} className={classes.row}>
          <Grid item lg={3} sm={12} className={classes.row}>
            <DatePicker />
          </Grid>
        </Grid>
        <PurchasesTable />
      </Page>
    </ScreenPurchasesStoreProvider>
  );
});
