import { languageFromServer } from "commonStores/analytics/userInfoStore";
import { languages } from "./languages";
import { getCurrentPath } from "utils/direction/getCurrentPath";

export function getLanguage() {
    const { languageCode } = languageFromServer;
    const { isOrganizationLoginPath, isOrganizationPath } = getCurrentPath();

    if (isOrganizationLoginPath || (isOrganizationPath && [languages.ENGLISH, languages.SPANISH].includes(languageCode))) {
        return languages.ENGLISH;
    }

    return languages.HEBREW;
}