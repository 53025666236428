import React from "react";
import { observer } from "mobx-react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

export const InputForm = observer(({ onHandleChange, isError, label, isRequired, subText, value, fieldName }) => {
    return (
        <Grid item md={6} xs={12}>
            <TextField
                fullWidth
                label={label}
                name={fieldName}
                onChange={onHandleChange}
                required={isRequired}
                value={value}
                error={isError}
                helperText={subText}
                variant="outlined"
                type="number"
                onWheel={event => event.target.blur()}
            />
        </Grid>
    );
});