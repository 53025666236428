import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        marginLeft: 0.5
    }
}));

export const FormWrapper = (props) => {
    const classes = useStyles();

    return (
        <form className={classes.row} onSubmit={props.onSubmit}>
            {props.children}
        </form>
    );
}