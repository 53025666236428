import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenUserBalanceStore } from "../store/index";
import MUIDataTable from "mui-datatables";
import { formatNumber } from "common/formatNumber";
import { onUserBalanceExcelDownload } from "../logic/createExel/onUserBalanceExcelDownload";
import { tableDataOptions } from "common/ui/tableDataOptions";

export const UserBalanceTable = observer(() => {
  const store = useScreenUserBalanceStore();
  const { userBalanceTableData, errorCode, userName, userIdentifier, isCanDownloadData } = store;
  const { options, columns } = getTableData({ userIdentifier, userBalanceTableData, isCanDownloadData });

  if (errorCode || (userBalanceTableData.length == 0 && !userName)) {
    return null;
  }

  return (
    <MUIDataTable
      title={t("screenUserBalance.table.title", { userName })}
      data={userBalanceTableData}
      columns={columns}
      options={options}
    />
  );
});

const getTableData = ({ userIdentifier, userBalanceTableData, isCanDownloadData }) => {
  const columns = [
    {
      name: "date",
      label: t("screenUserBalance.table.col.date"),
      options: tableDataOptions
    },
    {
      name: "action",
      label: t("screenUserBalance.table.col.business"),
      options: tableDataOptions
    },
    {
      name: "cost",
      label: t("screenUserBalance.table.col.cost"),
      options: {
        ...tableDataOptions,
        customBodyRender: (value) => {
          const styles = { green: { color: "green" }, red: { color: "red" } };
          const text = value >= 0 ? formatNumber(value) : formatNumber(Math.abs(Number(value))) + " -";
          const style = value > 0 ? styles.green : styles.red;
          return <p style={{ ...style, textAlign: 'right' }}>{text}</p>;
        },
      },
    },
    {
      name: "total",
      label: t("screenUserBalance.table.col.total"),
      options: {
        ...tableDataOptions,
        customBodyRender: (value) => {
          const text = value >= 0 ? formatNumber(value) : formatNumber(Math.abs(Number(value))) + " -";
          return <p style={{ textAlign: 'right' }}>{text}</p>;
        },
      },
    },
  ];

  const options = {
    download: isCanDownloadData,
    onDownload: () => onTableDownload({ userIdentifier, userBalanceTableData }),
    filter: false,
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: t("screenUserBalanceUser.table.col.noMatch"),
      },
      toolbar: {
        downloadCsv: "Download Excel file"
      }
    },
  };
  return { options, columns };
};

function onTableDownload({ userIdentifier, userBalanceTableData }) {
  onUserBalanceExcelDownload({ userIdentifier, userBalanceTableData });

  return false; // prevents default CSV file download
}