import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { InputBloodPressure } from './components/inputBloodPressure';
import { InputPushups } from './components/inputPushups';
import { InputWeight } from './components/inputWeight';
import { InputStar } from './components/inputStar';
import { InputWaistCircumference } from './components/inputWaistCircumference';
import { InputSquat } from './components/inputSquat';
import { InputFatPercentage } from './components/inputFatPercentage';
import { InputRowing } from './components/inputRowing';
import { InputsDivider as Divider } from './components/inputsDivider';

const useStyles = makeStyles(() => ({
    root: { marginTop: 15, marginBottom: 15 }
}));

export const InputMeasurements = observer(({ stage }) => {
    const classes = useStyles();

    return (
        <Grid container xs={12} sm={8} spacing={5} className={classes.root}>
            <InputBloodPressure stage={stage} />
            <InputWeight stage={stage} />
            <InputFatPercentage stage={stage} />
            <InputWaistCircumference stage={stage} />
            <Divider />
            <InputStar stage={stage} />
            <InputSquat stage={stage} />
            <InputPushups stage={stage} />
            <InputRowing stage={stage} />
        </Grid>
    );
});