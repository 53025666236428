import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { SuppliersDropDown } from '../monthlySupplierReport/suppliersDropDown';
import { SelectDate } from '../monthlySupplierReport/selectDate'
import { SuccessSendMonthlyReportPopUp } from '../monthlySupplierReport/successSendMonthlyReport';
import { ErrorSentReportFaild } from '../monthlySupplierReport/errorSentReportFaild';
import { MonthlyReportButton } from '../monthlySupplierReport/monthlyReportButton';
import { ProjectManagerHomeTitle } from '../monthlySupplierReport/projectManagerHomeTitle';

export const SummaryMonthlyReportBySupplier = observer(() => {
  const store = useScreenHomeStore();

  if (!store.isProjectManager) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      <ProjectManagerHomeTitle />
      <SelectDate />
      <SuppliersDropDown store={store} />
      <SuccessSendMonthlyReportPopUp />
      <ErrorSentReportFaild />
      <MonthlyReportButton />
    </Grid>
  )
});