import React from "react";
import { observer } from "mobx-react";
import { Typography, Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";
import { getHomeExcel } from "../store/logic/createExcel/getHomeExcel";
import ChangeSegmentDropDown from "./changeSegmentDropDown";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightSide: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
  leftSide: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export const Header = observer(() => {
  const classes = useStyles();
  const store = useScreenHomeStore();
  const { selectedSegmentName } = store;
  const onClickDownloadToExcel = () => {
    getHomeExcel(store);
  };
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item md={6} xs={12} className={classes.rightSide}>
        <Typography className={classes.title} component="h2" gutterBottom variant="overline"> {selectedSegmentName} </Typography>
        <Typography component="h1" gutterBottom variant="h3"> {t("screenHome.overview.title")} </Typography>
      </Grid>
      <Grid item md={6} xs={12} className={classes.leftSide}>
          <ChangeSegmentDropDown />
          <Button
            className={classes.searchButton}
            onClick={onClickDownloadToExcel}
            variant="contained"
          >
            {t("screenCouponsInInventory.downloadExcel")}
          </Button>
       </Grid>
     </Grid>
  );
});
