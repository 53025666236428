import React from "react";
import { makeObservable, action, observable } from "mobx";
import { getAppOpenByHours } from "../store/routes/getAppOpenByHours";
import { getPeriodsForAppOpenByHours } from "../store/logic/getPeriodsForAppOpenByHours";
import { getMoment } from "../store/logic/getMoment";

const ScreenAppOpenByHoursStoreContext = React.createContext({});
export const ScreenAppOpenByHoursStoreProvider = ScreenAppOpenByHoursStoreContext.Provider;
export const useScreenAppOpenByHoursStore = () => React.useContext(ScreenAppOpenByHoursStoreContext);

class ScreenAppOpenByHoursStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
    makeObservable(this, {
      isFetching: observable,
      periodOptions: observable,
      currentPeriodIndexAppOpen: observable,
      appOpenByHoursCountGraphData: observable,
      startDate: observable,
      getScreenData: action.bound,
      initProperties: action.bound,
      setStartDate: action.bound,
      setCurrentPeriodIndexAppOpen: action.bound,
      setIsFetching: action.bound,
      setPeriodFromStartDate: action.bound,
      setAppOpenByHoursCountGraphData: action.bound
    });
  }

  initProperties() {
    this.isFetching = false;
    this.appOpenByHoursCountGraphData = 0;
    this.periodOptions = getPeriodsForAppOpenByHours();
    this.currentPeriodIndexAppOpen = 1;
    const moment = getMoment('he');
    this.startDate = moment().subtract('days', 3);
  }

  getScreenData = async () => {
    this.setIsFetching(true);
    const period = this.periodOptions[this.currentPeriodIndexAppOpen];
    const segmentId = Number(this.rootStore.userInfoStore.currentSegmentId);
    const data = await getAppOpenByHours(segmentId, this.startDate, period.type);
    this.setAppOpenByHoursCountGraphData(data);
    this.setIsFetching(false);
  }

  setStartDate(startDate) {
    this.startDate = startDate;
    this.getScreenData(true);
  }

  setAppOpenByHoursCountGraphData(appOpenByHoursCountGraphData) {
    this.appOpenByHoursCountGraphData = appOpenByHoursCountGraphData;
  }

  setPeriodFromStartDate(periodFromStartDate) {
    this.periodFromStartDate = periodFromStartDate;
  }

  setCurrentPeriodIndexAppOpen(index) {
    this.currentPeriodIndexAppOpen = index;
    this.getScreenData();
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

}

export function createScreenAppOpenByHoursStore(rootStore) {
  const store = new ScreenAppOpenByHoursStore(rootStore);
  return store;
}
