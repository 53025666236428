import React from "react";
import { MailingConfirmationsWithDates } from './confirmationsMailingWithDate'
import { MailingConfirmationsWithoutDates } from './mallingWithoutDates'

export const MailingConfirmations = props => {
    const { confirmationsData, columns } = props;

    return (
        confirmationsData.mailingConfirmationsDates ?
            confirmationsData.mailingConfirmationsDates.reverse().map((item) =>
                <MailingConfirmationsWithDates item={item} columns={columns} />)
            : <MailingConfirmationsWithoutDates columns={columns} confirmationsData={confirmationsData} />
    );
}