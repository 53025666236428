const MuiTextField = {
    styleOverrides: {
        root: {
            "& label": {
                transformOrigin: "top right",
                right: 28,
                left: "auto"
            },
            "& legend": {
                textAlign: "right"
            },
            "& .MuiFormHelperText-root": {
                textAlign: "right",
                marginRight: '1px',
                marginLeft: '1px',
            },
        }
    }
};

export default MuiTextField;