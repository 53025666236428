export function getCouponsTableData(listOfCoupons) {
  if (!listOfCoupons) return [];

  const tableDataArray = listOfCoupons.map(item => [
    item.date,
    item.ownerId,
    item.businessName,
    item.couponName,
    item.couponCode,
    item.expireDate,
    item.submitterEmail,
    item.dateSubmittedForHide,
  ]);
  
  return tableDataArray;
}
