import { errorCodes } from './errorCodes'
import { confirmationsErrors } from "./errorsTypesText";
export const getErrorTextByErrorCode = (errorCode) => {
  if (!errorCode) {
    return;
  }
  switch (errorCode) {
    case errorCodes.ERROR_TYPE_USER_NOT_EXISTS:
      return confirmationsErrors.userNotExsist;

    case errorCodes.ERROR_TYPE_INVALID_DATA:
      return confirmationsErrors.invalidInput;

    case errorCodes.ERROR_TYPE_ID_TOO_SHORT:
      return confirmationsErrors.tooShort;

    case errorCodes.ERROR_TYPE_NOT_ONLY_NUMBERS:
      return confirmationsErrors.onlyNumbers;
    default:
      return confirmationsErrors.userNotExsist;
  }
};