import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useScreenCouponsStore } from "../store/index";
import { Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    color: theme.palette.white,
    marginRight: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondGraphColor,
    },
  },
  tooltip: {
    fontSize: 14,

  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginRight: 10,
  },
}));

export const ExposeAndCancelButtons = observer((props) => {
  const recoverTooltipTitle = (
    <div>
      {t("screenCouponUser.button.recover.1")} <br />
      {t("screenCouponUser.button.recover.2")} <br />
      {t("screenCouponUser.button.recover.3")}
    </div>
  );

  const deleteTooltipTitle = (
    <div>
      {t("screenCouponUser.button.delete.1")} <br />
      {t("screenCouponUser.button.delete.2")} <br />
      {t("screenCouponUser.button.delete.3")}
    </div>
  );

  const store = useScreenCouponsStore();
  const { isNowRowSelected, errorCode, couponsUserTableData, userName } = store;

  const isDisabled = !isNowRowSelected;

  const classes = useStyles();

  if (errorCode || (couponsUserTableData.length === 0 && !userName)) {
    return null;
  }
  const handleClickOpenCancelPopUp = () => {
    store.setCouponName();
    store.deleteCouponPopUp(true);
  };

  const handleClickOpenExposePopUp = () => {
    store.setCouponName();
    store.exposeCouponPopUp(true);
  };
  return (
    <Grid>
      <Button
        disabled={isDisabled}
        className={classes.buttons}
        size="small"
        type="submit"
        variant="contained"
        onClick={handleClickOpenExposePopUp}
      >
        {t("screenCouponsUser.unHide")}
      </Button>
      <Tooltip title={recoverTooltipTitle} classes={classes}>
        <HelpIcon className={classes.toolTipIcon} />
      </Tooltip>
      <Button
        disabled={isDisabled}
        className={classes.buttons}
        size="small"
        type="submit"
        variant="contained"
        onClick={handleClickOpenCancelPopUp}
      >
        {t("screenCouponsUser.cancelCoupon")}
      </Button>
      <Tooltip title={deleteTooltipTitle} classes={classes}>
        <HelpIcon className={classes.toolTipIcon} />
      </Tooltip>
    </Grid>
  );
});
