import React from "react";
import { observer } from "mobx-react";
import Grid from "@mui/material/Unstable_Grid2";
import { FacebookCube } from '../components/totalNumbersSocialNetworks/facebookCube';
import { InstagramCube } from '../components/totalNumbersSocialNetworks/instagramCube';
import { PhoneCube } from '../components/totalNumbersSocialNetworks/phoneCube';
import { SiteCube } from '../components/totalNumbersSocialNetworks/siteCube';



export const TotalNumbersSocialNetworks = observer(() => {

    return (
        <Grid container spacing={3}>
           <SiteCube />
           <PhoneCube />
           <InstagramCube />
           <FacebookCube />
        </Grid>
    );
});
