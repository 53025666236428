import { segmentsPaths } from "commonStores/analytics/userInfoStore/logic/getSegmentPathById";
import { WHITE } from "../../../../common/businessDashboardColors";

export function getCustomTopBarColor(currentSegment) {

    const segmentIds = currentSegment && currentSegment.segmentIds;

    if (segmentIds && segmentIds.includes(segmentsPaths.POLICE.id)) {
        return "primary";
    }

    return WHITE;
}