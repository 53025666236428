import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { DataCard } from './components/dataCard';
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from '../../store/storeHealthAndFitnessDataStoreContext';

export const StageDataCards = observer(() => {
    const store = useHealthAndFitnessDataStore();
    const { totalCount, currentCountStart, currentCountFinish } = store;

    return (
        <Grid container spacing={2}>
            <DataCard
                label={t("screenHealthAndFitnessData.data.start")}
                subtitle={t("screenHealthAndFitnessData.data.systemFeed")}
                value={`${currentCountStart}/${totalCount}`}
                isFetching={store.isFetching} />
            <DataCard
                label={t("screenHealthAndFitnessData.data.finish")}
                subtitle={t("screenHealthAndFitnessData.data.systemFeed")}
                value={`${currentCountFinish}/${totalCount}`}
                isFetching={store.isFetching} /> 
        </Grid>
    );
});