/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {},
  errorText: {
    color: "red",
    height: theme.spacing(5),
    marginTop: theme.spacing(1),
  },
}));

const ErrorText = (props) => {
  const { errorText } = props;
  const classes = useStyles();
  return <Typography className={classes.errorText}>{errorText}</Typography>;
};

export default ErrorText;
