import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { Page } from "components";
import Grid from "@mui/material/Unstable_Grid2";
import { t } from "common/localization/translate";
import { TitlesOrderDetails } from "./components/titlesOrderDetails";
import { OrderDetailsTable } from './components/orderDetailsTable';
import { OrderIdForm } from './components/orderIdForm/index';
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { Redirect } from "react-router-dom";

import { createOrderDetailsUsersStore, ScreenOrderDetailsDataProvider } from "./store";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  }
}));

export const OrderDetails = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createOrderDetailsUsersStore (rootStore));
  const classes = useStyles();
  if (!rootStore.userInfoStore.isShowOrderDetailsScreen) {
    return (
      <Redirect to={{ pathname: `/${dashboardPaths.SUPPORT}/home` }} />
    );
  }
  return (
    <ScreenOrderDetailsDataProvider value={store}>
      <Page className={classes.root} title={t("screenUserBalanceUser.title")}>
        <Grid className={classes.container} container item lg={12} xs={12} spacing={2}>
          <TitlesOrderDetails />
          <OrderIdForm />
        </Grid>
        <br />
        <OrderDetailsTable />
      </Page>
    </ScreenOrderDetailsDataProvider>
  );
});
