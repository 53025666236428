export const policeErrorCodes = {
  ERROR_TYPE_USER_NOT_EXISTS: 1,
  ERROR_TYPE_INVALID_DATA: 2,
  ERROR_TYPE_ID_TOO_SHORT: 3,
  ERROR_TYPE_NOT_ONLY_NUMBERS: 4,
  ERROR_TYPE_NO_INPUT_DATA: 5,
  ERROR_TYPE_DATA_NOT_SAVED: 6,
  ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED: 7,
  NO_SEGMENT_SELECTED: 8
};

export const sectionErrorCodeStr = {
  start: 'errorCodeSaveStart',
  finish: 'errorCodeSaveFinish'
}