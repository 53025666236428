import { isShowPickUpInfo } from "./isShowPickUpInfo";

export function getPurchasesUsersInfoTableData(store, segmentId) {
  const { segmentCouponPurchasersInfo } = store;
  const tableDataArray = segmentCouponPurchasersInfo
    ? segmentCouponPurchasersInfo.map((item) => {
        const array = [
          item.couponTitle,
          item.userIdentifier,
          item.phone,
          item.userName,
          item.date,
        ];
        if (item.segmentName) {
          array.push(item.segmentName);
        }

        if(item.couponCode){
          array.splice(1, 0, item.couponCode);
        }

        if(isShowPickUpInfo(segmentId)) {
          array.splice(1, 0, item.pickUpPoint );
        }

        return array;
      })
    : [];
  return tableDataArray;
}
