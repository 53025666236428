import moment from "moment";

export function getNowInMoment() {
  return moment();
}

export function convertDateToMoment(date) {
  return moment(date);
}

export function startOfDay(momentDate) {
  return momentDate.clone().startOf("day");
}

export function subtractFromDate(momentDate, amount, type) {
  return momentDate.clone().subtract(amount, type);
}

