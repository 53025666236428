import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { CommonButton } from "common/ui/commonButton";
import { couponsErrorCodes } from "../../../coupons/store/logic/couponsErrorCodes";
import { useScreenConfirmHideCouponsStore } from "../../store";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.white,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 14,
    },
  },
  content: { marginTop: 8 },
  buttons: {
    fontSize: 14,
    marginLeft: 21,
    marginTop: 18,
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.secondGraphColor,
    }
  },
  dialogDiv: { width: 500, height: 200 },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  }
}));

export const RestoreCouponPopUp = observer(() => {
  const store = useScreenConfirmHideCouponsStore();
  const { couponName, setIsOpenRestoreCouponPopUp } = store;
  const classes = useStyles();

  const onClose = () => {
    setIsOpenRestoreCouponPopUp(false);
  };

  if (!store.statusMessageHidingCoupon) {
    return null;
  }

  const errorCode = store.statusMessageHidingCoupon.errorCode;
  let text = t("screenCouponsUser.successCouponRestored", { couponName })
  if (errorCode == couponsErrorCodes.ERROR_TYPE_COUPON_ALREADY_HIDDEN) {
    text = t("screenCouponsUser.popUp.alreadyHidden", { couponName });
  }
  if (errorCode == couponsErrorCodes.ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED) {
    text = t("screenCouponsUser.Modal.limitReached")
  }

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={store.isRestoreCouponPopUpVisible}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.dialogDiv}>
          <DialogTitle className={classes.root} id="responsive-dialog-title">
            {t("screenConfirmHideCouponsUser.button.cancelHiding")}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText>{text}</DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonsContainer}>
            <CommonButton className={classes.buttons} onClick={onClose}>
              {t("screenCouponsUser.popUp.closeButton")}
            </CommonButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});
