import React from "react";
import { observer } from "mobx-react";
import { TextField } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { useScreenConfirmationsUseresStore } from "../../../store/index";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  input: {
    width: "155px",
  }
}));

export const InputConfirmations = observer((props) => {
  const classes = useStyles();
  const store = useScreenConfirmationsUseresStore();
  const { errorText, idInput } = props;
  const inputProps = { maxLength: 10 };
  const handleInputChange = (event) => {
    const { value } = event.target;
    store.setUserId(value);
  };
  return (
    <Grid item sm={4} lg={2}>
      <TextField
        className={classes.input}
        id="standard-basic"
        onChange={handleInputChange}
        type="string"
        placeholder={idInput}
        value={store.userIdNum}
        error={store.errorCode}
        helperText={errorText}
        inputProps={inputProps}
        variant="standard"
      />
    </Grid>
  );
});
