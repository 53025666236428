import { getNowInMoment } from "common/dateAndTime/moment";

export async function downloadWorksheet(workbook, fileName) {
    const todayStr = getNowInMoment().format('DD-MM-YY');
    const buffer = await workbook.xlsx.writeBuffer();
    const base64 = buffer.toString('base64');
    var a = document.createElement("a");
    a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet/png;base64," + base64; //Image Base64 Goes here
    a.download = `${fileName} ${todayStr}.xlsx`;
    a.click();
}