export default {
  styleOverrides: {
    contained: {
      boxShadow:
      '0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0px rgba(0,0,0,0.20)',
      backgroundColor: '#FFFFFF',
      color: 'black',
      '&:hover': {
        backgroundColor: '#CCCCCC',
      },
    }
  }
};
