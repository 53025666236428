import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { ExportToExcel } from "../stageDataCardsAndExport/components/exportToExcel";
import { useHealthAndFitnessDataStore } from "../../store/storeHealthAndFitnessDataStoreContext";
import { StartMeasurementsData } from "./components/startMeasurementsData";
import { FinishMeasurementsData } from "./components/finishMeasurementsData";
import { SelectDataTypeText } from "./components/selectDataTypeText";
import { SuccessSaveDataPopUp } from "./components/successSaveDataPopUp";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        display: "flex",
        flexDirection: 'column',
        marginLeft: 0.5
    },
    header: {
        fontSize: "20px",
        lineHeight: "18px",
        marginBottom: 20
    },
}));

export const MeasurementsData = observer(() => {
    const store = useHealthAndFitnessDataStore();
    const classes = useStyles();

    const handleExportToExcel = () => {
        store.exportSinglePoliceDataToExcel();
    }

    if (!store.userName || store.isFetchingCopData) {
        return null;
    }

    return (
        <Grid className={classes.row} container lg={12} xs={12} spacing={1}>
            <SelectDataTypeText />
            <StartMeasurementsData />
            <FinishMeasurementsData />
            <ExportToExcel label={t("screenHealthAndFitnessData.data.export.oneCop")} onClick={handleExportToExcel} />
            <SuccessSaveDataPopUp />
        </Grid>
    );
});
