import { t } from "common/localization/translate";
import { errorCodesOtpCode } from './errorCodesOtpCode';

export function convertOtpErrorToMsg(errorCode) {
    switch (errorCode) {
        case errorCodesOtpCode.RATE_LIMITER_EXCEEDED:
            return t('OtpCode:errorText.rateLimiterExceeded');
        case errorCodesOtpCode.INVALID_CODE:
            return t('OtpCode:errorText.invalidCode');
        case errorCodesOtpCode.INVALID_PHONE_NUMBER:
            return t('OtpCode:errorText.invalidPhoneNumber');
        case errorCodesOtpCode.PHONE_NUMBER_ALREADY_USED:
            return t('OtpCode:errorText.phoneHasBeenUsed');
        default:
            return t('OtpCode:errorText.general');

    }
}
