import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { t } from "common/localization/translate";
import { createBillingReportSystemStore, BillingReportSystemStoreProvider} from "./store/index";
import { RejectCodes } from "./components/rejectCodes/rejectCodesTextbox";
import { GetAllPurchasesButton } from "./components/purchaseReports/getAllPurchasesButton";
import { IsFinalDraft } from "./components/purchaseReports/isFinalDraftCheckbox";
import { SelectDate } from "./components/selectDate";
import { IsPerSupplierCheckbox } from "./components/purchaseReports/isPerSupplierCheckbox";
import { SupplierMultiSelect } from "./components/purchaseReports/supplierMultiSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    width: "100%",
    height: "100%",

  },
}));
export const BillingReportSystem = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createBillingReportSystemStore(rootStore));
  const classes = useStyles();
  
  useEffect(() => { 
    store.getScreenData()
  }, []);
  if (!store.isProjectManager) {
    return null;
  }

  return (
    <BillingReportSystemStoreProvider value={store}>
      <Page title={t("suppliers.home.suuplierDashboardTitle")} className={classes.root}  >
        <Grid container spacing={3} >
          <SelectDate />
          <RejectCodes />
          <IsFinalDraft />
          <IsPerSupplierCheckbox />
          <SupplierMultiSelect />
          <GetAllPurchasesButton />
        </Grid>
      </Page>
    </BillingReportSystemStoreProvider>
  )
});

