import React from "react";
import { observer, useObserver } from "mobx-react";
import { t } from "common/localization/translate";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useScreenEntitlementStatusStore } from "../../store";

export const EntitlementHistoryTable = observer(() => {

    return useObserver(() => {
        const store = useScreenEntitlementStatusStore();
        const entitlementHistory = store.entitlementHistory;

        if (!entitlementHistory) {
            return null;
        };

        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('screenEntitlementData.entitlementHistoryTable.date')}</TableCell>
                            <TableCell>{t('screenEntitlementData.entitlementHistoryTable.status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {entitlementHistory.map((row) => (
                            <TableRow
                                key={row.date}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{row.date}</TableCell>
                                <TableCell align="left">{row.value ? t('screenEntitlementData.entitled') : t('screenEntitlementData.notEntitled')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    })
});
