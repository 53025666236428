export const editUserInfoErrorCodes = {
  ERROR_TYPE_USER_NOT_EXISTS: 1,
  ERROR_TYPE_INVALID_ID_NUM: 2,
  ERROR_TYPE_EMPTY_EMAIL: 3,
  ERROR_TYPE_INVALID_EMAIL: 4,
  ERROR_TYPE_ALREADY_USED_EMAIL: 5,
  ERROR_TYPE_RATE_LIMITER_IS_EXCEEDED : 6,
  ERROR_TYPE_INVALID_PHONE : 7,
  ERROR_TYPE_ID_TOO_SHORT: 100,
  ERROR_TYPE_NOT_ONLY_NUMBERS: 101,
  ERROR_TYPE_EMPTY_USER_NAME: 102,
};
