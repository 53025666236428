import React from "react";
import { makeObservable, action, observable, computed } from "mobx";
import { getHomeScreenDataFromServer } from './server/getHomeScreenDataFromServer';
import { getClickByCouponTypeForChart } from './logic/getClickByCouponTypeForChart'
import { getPeriods} from "../store/logic/periodsGraphs";
import { getImpressionForBusinessFromServer } from "./server/getImpressionForBusinessFromServer"
import { getClicksForBusinessFromServer } from './server/getClicksForBusinessFromServer'

const ScreenHomeStoreContext = React.createContext({});
export const ScreenHomeStoreProvider = ScreenHomeStoreContext.Provider;
export const useScreenHomeStore = () =>
  React.useContext(ScreenHomeStoreContext);

class ScreenHomeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();

    this.isFetchingImpression = null;
    this.isFetchingClicks = null;
    this.categoryName = null;
    this.clicksOnSiteBusiness = null;
    this.clicksOnFacebookBusiness = null;
    this.clicksOnInstagramBusiness = null;
    this.clicksOnPhoneBusiness = null;
    this.categoryPosition = null;
    this.businessesInTheCategory = null;
    this.amountOfImpresionsForBusiness = null;
    this.clickForBusiness = null;
    this.amountOfPurchaseCouponsForBusiness = null;
    this.amountOfClicksByCouponType = null;
    this.impressionForBusinessGraphData = null;
    this.amountOfImpresionsForBusinessGraphData = null;
    this.amountOfClicksForBusinessGraphData = null;
    this.purchasesPerCoupon = null;

    makeObservable(this, {
      isFetching: observable,
      isFetchingImpression:observable,
      isFetchingClicks:observable,
      categoryName:observable,
      clicksOnSiteBusiness:observable,
      clicksOnFacebookBusiness:observable,
      clicksOnInstagramBusiness:observable,
      clicksOnPhoneBusiness:observable,
      categoryPosition: observable,
      businessesInTheCategory: observable,
      amountOfImpresionsForBusiness: observable,
      clickForBusiness: observable,
      amountOfPurchaseCouponsForBusiness: observable,
      amountOfClicksByCouponType: observable,
      impressionForBusinessGraphData:observable,
      currentPeriodIndexImpressionBusiness:observable,
      currentPeriodIndexClicksOnBusiness:observable,
      amountOfImpresionsForBusinessGraphData:observable,
      amountOfClicksForBusinessGraphData:observable,
      purchasesPerCoupon:observable,
      setCategoryName:action.bound,
      getImpressionGraphData:action.bound,
      setCurrentPeriodIndexImpressionBusiness:action.bound,
      setAmountOfImpresionsForBusinessGraphData: action.bound,
      setAmountOfClicksByCouponType: action.bound,
      setIsFetching: action.bound,
      setCurrentPeriodIndexClicksOnBusiness:action.bound,
      setCategoryPosition: action.bound,
      setBusinessesInTheCategory: action.bound,
      setAmountOfImpresionsForBusiness: action.bound,
      setAmountOfPurchaseCouponsForBusiness: action.bound,
      setClickForBusiness: action.bound,
      initProperties: action.bound,
      getScreenData: action.bound,
      setIsFetchingImpression:action.bound,
      setIsFetchingClicks:action.bound,
      setClicksOnSiteBusiness:action.bound,
      setClicksOnFacebookBusiness:action.bound,
      setClicksOnInstagramBusiness: action.bound,
      setClicksOnPhoneBusiness:action.bound,
      setPurchasesPerCoupon:action.bound,
      arrayOfClicksByCouponType: computed
    });
  }

  initProperties() {
    this.businessId = this.rootStore.userInfoStore.currentBusinessId.businessId;
    this.isFetching = true;
    this.periodOptions = getPeriods();
    this.currentPeriodIndexImpressionBusiness = 2;
    this.currentPeriodIndexClicksOnBusiness = 2;
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }


  setAmountOfClicksForBusinessGraphData(amountOfClicksForBusinessGraphData){
    this.amountOfClicksForBusinessGraphData=amountOfClicksForBusinessGraphData;
  }

  setImpresionBusinessPeriod(impresionBusinessPeriod) {
    this.impresionBusinessPeriod = impresionBusinessPeriod;
  }

  setClicksBusinessPeriod(clicksBusinessPeriod) {
    this.clicksBusinessPeriod = clicksBusinessPeriod;
  }

  setCurrentPeriodIndexImpressionBusiness(index){
    this.currentPeriodIndexImpressionBusiness = index;
    this.getImpressionGraphData();
  }

  setCurrentPeriodIndexClicksOnBusiness(index){
    this.currentPeriodIndexClicksOnBusiness = index;
    this.getClicksGraphData();
  }


  getScreenData = async () => {
    await this.initProperties();
    this.setIsFetching(true);
    this.setIsFetchingClicks(true);
    this.setIsFetchingImpression(true);
    const impressionBusinessPeriod  = this.periodOptions[this.currentPeriodIndexImpressionBusiness].type;
    const clicksBusinessPeriod = this.periodOptions[this.currentPeriodIndexClicksOnBusiness].type;
    const data = await getHomeScreenDataFromServer({businessId: this.businessId, impressionBusinessPeriod, clicksBusinessPeriod});
    const { categoryPosition, businessesInTheCategory, amountOfImpresionsForBusiness,
      clickForBusiness, amountOfPurchaseCouponsForBusiness, clicksByCouponTypeId,
      impressionForBusinessGraphData,clicksForBusinessGraphData, clicksOnSiteBusiness,
      clicksOnFacebookBusiness, clicksOnInstagramBusiness,clicksOnPhoneBusiness, categoryName, purchasesPerCoupon } = data.extra;
    this.setCategoryPosition(categoryPosition);
    this.setCategoryName(categoryName);
    this.setAmountOfImpresionsForBusinessGraphData(impressionForBusinessGraphData.cleanAndFilledArray);
    this.setAmountOfClicksForBusinessGraphData(clicksForBusinessGraphData.cleanAndFilledArray);
    this.setBusinessesInTheCategory(businessesInTheCategory);
    this.setAmountOfImpresionsForBusiness(amountOfImpresionsForBusiness);
    this.setClickForBusiness(clickForBusiness);
    this.setAmountOfPurchaseCouponsForBusiness(amountOfPurchaseCouponsForBusiness);
    this.setAmountOfClicksByCouponType(clicksByCouponTypeId);
    this.setClicksOnSiteBusiness(clicksOnSiteBusiness);
    this.setClicksOnFacebookBusiness(clicksOnFacebookBusiness);
    this.setClicksOnInstagramBusiness(clicksOnInstagramBusiness);
    this.setClicksOnPhoneBusiness(clicksOnPhoneBusiness);
    this.setPurchasesPerCoupon(purchasesPerCoupon);
    this.setIsFetching(false);
    this.setIsFetchingClicks(false);
    this.setIsFetchingImpression(false);
  }

  setPurchasesPerCoupon(purchasesPerCoupon){
    this.purchasesPerCoupon = purchasesPerCoupon;
  }

  setCategoryName(categoryName){
    this.categoryName = categoryName;
  }
  setClicksOnSiteBusiness(clicksOnSiteBusiness){
    this.clicksOnSiteBusiness = clicksOnSiteBusiness;
  }
  setClicksOnFacebookBusiness(clicksOnFacebookBusiness){
    this.clicksOnFacebookBusiness = clicksOnFacebookBusiness;
  }
  setClicksOnInstagramBusiness(clicksOnInstagramBusiness){
    this.clicksOnInstagramBusiness=clicksOnInstagramBusiness;
  }
  setClicksOnPhoneBusiness(clicksOnPhoneBusiness){
    this.clicksOnPhoneBusiness=clicksOnPhoneBusiness;
  }


  setCategoryPosition(categoryPosition) {
    this.categoryPosition = categoryPosition;
  }

  setAmountOfImpresionsForBusiness(amountOfImpresionsForBusiness) {
    this.amountOfImpresionsForBusiness = amountOfImpresionsForBusiness;
  }

  setClickForBusiness(clickForBusiness) {
    this.clickForBusiness = clickForBusiness;
  }

  setAmountOfPurchaseCouponsForBusiness(amountOfPurchaseCouponsForBusiness) {
    this.amountOfPurchaseCouponsForBusiness = amountOfPurchaseCouponsForBusiness;
  }

  setBusinessesInTheCategory(businessesInTheCategory) {
    this.businessesInTheCategory = businessesInTheCategory;
  }

  setAmountOfClicksByCouponType(amountOfClicksByCouponType) {
    this.amountOfClicksByCouponType = amountOfClicksByCouponType;
  }

  setAmountOfImpresionsForBusinessGraphData(amountOfImpresionsForBusinessGraphData) {
    this.amountOfImpresionsForBusinessGraphData = amountOfImpresionsForBusinessGraphData;
  }

  setIsFetchingImpression(isFetchingImpression){
    this.isFetchingImpression = isFetchingImpression;
  }
  
  setIsFetchingClicks(isFetchingClicks){
    this.isFetchingClicks = isFetchingClicks;
  }

  async getImpressionGraphData(){
    this.setIsFetchingImpression(true);
    const impressionBusinessPeriod  = this.periodOptions[this.currentPeriodIndexImpressionBusiness].type;
    const impressionForBusinessGraphData = await getImpressionForBusinessFromServer({businessId : this.businessId, impressionBusinessPeriod});
    this.setAmountOfImpresionsForBusinessGraphData(impressionForBusinessGraphData.cleanAndFilledArray);
    this.setIsFetchingImpression(false);
  }

  async getClicksGraphData(){
    this.setIsFetchingClicks(true);
    const clicksBusinessPeriod = this.periodOptions[this.currentPeriodIndexClicksOnBusiness].type;
    const ClicksForBusinessGraphData = await getClicksForBusinessFromServer({businessId : this.businessId, clicksBusinessPeriod});
    this.setAmountOfClicksForBusinessGraphData(ClicksForBusinessGraphData.cleanAndFilledArray);
    this.setIsFetchingClicks(false);
  }

  get arrayOfClicksByCouponType() {
    return getClickByCouponTypeForChart(this);
  }
}

export function createScreenHomeStore(rootStore) {
  const store = new ScreenHomeStore(rootStore);
  return store;
}
