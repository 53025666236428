import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles(() => ({
    header: {
        fontSize: "18px",
        lineHeight: "18px",
        marginBottom: 20
    },
}));

export const SelectDataTypeText = () => {
    const classes = useStyles();
    return (
        <Typography className={classes.header} component="h2" variant="overline" gutterBottom>
            {t("screenHealthAndFitnessData.selectType.label")}
        </Typography>
    );
};