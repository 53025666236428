import React from "react";
import { makeObservable, action, observable } from "mobx";
import { getUserData as getScreenDataServer } from "./server/getScreenData";
import { editUserInfo } from "./server/editUserInfo";
import { SendResetPassword } from "./server/sendResetPassword";
import { OpenUniqueDeviceIdBlock } from "./server/openUniqueDeviceIdBlock";
import { t } from "common/localization/translate";
import { editUserInfoErrorCodes } from "./logic/editUserInfoErrorCodes";
import { validatePhoneNumber } from '../../editUserInfo/store/logic/validatePhoneNumber';
import { errorCodes } from '../../services/errorCodes';
import { validateIsraeliIdStructure } from "common/validateIsraeliIdStructure/validateIsraeliIdStructure";
import { sendOtpCode } from "./server/sendOtpCode";
import { clearAllExceptNumbers } from "../../../../common/clearInputs/clearAllExceptNumbers";
import { validateOtpCode } from "./server/validateOtpCode";
import { convertOtpErrorToMsg } from "./logic/convertOtpErrorToMsg";

const ScreenEditUserInfoStoreContext = React.createContext({});
export const ScreenEditUserInfoDataProvider = ScreenEditUserInfoStoreContext.Provider;
export const useScreenEditUserInfoStore = () => React.useContext(ScreenEditUserInfoStoreContext);

class ScreenEditUserInfoStore {
  constructor(rootStore) {
    this.rootStore = rootStore;

    this.userIdentifier = null;
    this.userEmail = null;
    this.userId = null;
    this.userName = null;
    this.phone = null;
    this.oldPhone = null;
    this.confirmedPhone = null;
    this.serverError = null;
    this.isSaveButtonDisabled = null;
    this.isFetching = null;
    this.isSending = null;
    this.errorCode = null;
    this.successText = null;
    this.isRemovedSpecialCharacters = null;
    this.isEntitled = null;
    this.segmentId = null;
    this.isShouldConfirmWithOtp = null;
    this.otpCode = null;
    this.otpErrorMessage = null;
    this.isOtpSendButtonVisible = null;
    this.isOtpSendButtonDisabled = null;
    this.isOtpInputVisible = null;
    this.isOtpInputDisabled = null;

    makeObservable(this, {
      userIdentifier: observable,
      userEmail: observable,
      userId: observable,
      userName: observable,
      phone: observable,
      oldPhone: observable,
      confirmedPhone: observable,
      serverError: observable,
      isSaveButtonDisabled: observable,
      isFetching: observable,
      isSending: observable,
      errorCode: observable,
      successText: observable,
      isRemovedSpecialCharacters: observable,
      isEntitled: observable,
      segmentId: observable,
      isShouldConfirmWithOtp: observable,
      otpCode: observable,
      otpErrorMessage: observable,
      isOtpSendButtonVisible: observable,
      isOtpSendButtonDisabled: observable,
      isOtpInputVisible: observable,
      isOtpInputDisabled: observable,
      setUserIdentifier: action.bound,
      setServerError: action.bound,
      setUserEmail: action.bound,
      setUserName: action.bound,
      setErrorCode: action.bound,
      setUserId: action.bound,
      setIsFetching: action.bound,
      setSuccessText: action.bound,
      setIsUserEntitled: action.bound,
      validatEditUserInfo: action.bound,
      submitUserChanges: action.bound,
      sendOtpCode: action.bound,
      handlePhoneChange: action.bound,
      handleOtpChange: action.bound,
    });    
  }

  setErrorCode(errorCode) {
    this.setSuccessText(null);
    this.errorCode = errorCode;
  }

  setUserIdentifier(userIdentifier) {
    this.userIdentifier = userIdentifier;
  }

  setServerError(errorNum) {
    this.serverError = errorNum;
  }

  setUserId(userId) {
    this.userId = userId;
  }

  setUserEmail(email) {
    this.userEmail = email;
    if(this.isRemovedSpecialCharacters){
      this.isRemovedSpecialCharacters = false;
    }
  }

  setUserName(userName) {
    this.userName = userName;
  }

  setIsUserEntitled(isEntitled) {
    this.isEntitled = isEntitled;
  }

  handlePhoneChange(phone) {
    this.phone = phone;
    this.setErrorCode(null);

    if (!this.isShouldConfirmWithOtp) return;

    this.otpErrorMessage = null;
    this.otpCode = null;
    this.isOtpInputDisabled = false;
    this.isOtpInputVisible = false;
    this.isOtpSendButtonDisabled = false;
    this.isOtpSendButtonVisible = false;

    const isNewPhoneValid = validatePhoneNumber(phone);
    if (!isNewPhoneValid) return;

    if (this.oldPhone.replaceAll("-", "") === phone.replaceAll("-", "")) {
      this.isSaveButtonDisabled = false;
      this.isOtpSendButtonVisible = false;
      this.isisOtpInputVisible = false;
      this.otpErrorCode = null;
      return;
    };

    this.isSaveButtonDisabled = true;
    this.isOtpSendButtonVisible = true;
  }

  async handleOtpChange(code) {
    this.otpErrorMessage = null;
    this.otpCode = clearAllExceptNumbers(code);
    
    if (this.otpCode.length < 4) return;
    
    this.isOtpInputDisabled = true;

    try {
      const res = await validateOtpCode({ userId: this.userId, idNum: this.userIdentifier, phone: this.phone, segmentId: this.segmentId, code: this.otpCode });
      if (res.isSuccess) {
        this.isOtpSendButtonDisabled = true;
        this.isSaveButtonDisabled = false;
        this.confirmedPhone = this.phone;
        this.oldPhone = this.phone;
      } else {
        this.otpErrorMessage = convertOtpErrorToMsg(res.errorCode);
        this.isOtpInputDisabled = false;
        this.isOtpSendButtonDisabled = false;
      }
    } catch (e) {
      this.otpCode = null;
      this.isOtpInputDisabled = false;
    }

  }

  async sendOtpCode() {
    const isValidPhone = validatePhoneNumber(this.phone);
    if(!isValidPhone) {
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_INVALID_PHONE);
      return;
    }

    this.otpCode = null;
    this.isOtpInputDisabled = true;
    this.isOtpInputVisible = true;
    this.isOtpSendButtonDisabled = true;

    try {
      const res = await sendOtpCode({ userId: this.userId, idNum: this.userIdentifier, phone: this.phone, segmentId: this.segmentId });
      if (res.isSuccess) {
        this.isOtpInputDisabled = false;
      } else {
        this.otpErrorMessage = convertOtpErrorToMsg(res.errorCode);
      }
    } catch (e) {
      this.otpErrorMessage = convertOtpErrorToMsg();
    }
  }

  async submitResetPassword() {
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const res = await SendResetPassword(this.userId, segmentId);
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenEditUserInfo.resetPassword.success', { email: this.userEmail}))
    }
    return res;
  }

  async unblockUniqueDeviceId() {
    if(this.isFetching){
      return; 
    }
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const res = await OpenUniqueDeviceIdBlock(this.userId, segmentId);
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenEditUserInfo.button.unblockUniqueDeviceId.success'))
    }
    this.setIsFetching(false);
  }

  async submitUserChanges() {
    if(this.isFetching){
      return; 
    }
    this.setErrorCode(null);
    this.validatEditUserInfo();
    if(this.errorCode){
      return;
    }
    this.setIsFetching(true);
    const segmentId = this.rootStore.userInfoStore.currentSegment ? this.rootStore.userInfoStore.currentSegment.segmentId : null;
    const res = await editUserInfo(this.userId, this.userName, this.userEmail, this.phone, segmentId, this.otpCode, this.confirmedPhone);
    this.setErrorCode(res.errorCode);
    if(res.isSuccess){
      this.setSuccessText(t('screenEditUserInfo.editInfo.sucessText'))
      this.userEmail = res.email;
    }
    if(res.extra && res.extra.isRemovedSpecialCharacters){
      this.isRemovedSpecialCharacters = res.extra.isRemovedSpecialCharacters;
    }
    this.setIsFetching(false);
  }

  submitIdentifier() {
    this.setUserName(null);
    const isValidId = validateIsraeliIdStructure(this.userIdentifier);
    const isValidPhone = validatePhoneNumber(this.userIdentifier, true);
    if(this.rootStore.userInfoStore.isWithSearchByPhoneOption){
      if(isValidId || isValidPhone){ 
        this.getUserData();
        return;
      }
      this.setErrorCode(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    if(!isValidId){
      this.setErrorCode(errorCodes.ERROR_TYPE_INVALID_DATA);
      return;
    }
    this.getUserData();

  }

  validatEditUserInfo(){
    if(!this.userName){
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_EMPTY_USER_NAME);
    }

    if(!this.userEmail){
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_EMPTY_EMAIL);
    }

    const isValidPhone = validatePhoneNumber(this.phone);
    if(!isValidPhone || (this.isShouldConfirmWithOtp && this.phone !== this.confirmedPhone)) {
      this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_INVALID_PHONE);
    }
  }

  async getUserData() {
    try{
    if(this.isFetching){
      return; 
    }
    this.setIsFetching(true);
    const { segmentId } = this.rootStore.userInfoStore.currentSegment || null;
    this.segmentId = segmentId;
    const res = await getScreenDataServer(this.userIdentifier, segmentId);
    this.setErrorCode(res.errorCode);
    this.setUserName(res.userName);
    this.setUserEmail(res.email);
    this.setUserId(res.userId);
    this.setIsUserEntitled(res.isEntitled);
    this.phone = this.oldPhone = this.confirmedPhone = res.phone;
    this.isShouldConfirmWithOtp = res.isShouldConfirmWithOtp;
    this.setIsFetching(false);
  }
  catch(e){
    this.setErrorCode(editUserInfoErrorCodes.ERROR_TYPE_USER_NOT_EXISTS);
    this.setIsFetching(false);
  }
  }

  setIsFetching(isFetching) {
    this.isFetching = isFetching;
  }

  setSuccessText(text) {
    this.successText = text;
  }
}

export function createScreenEditUserInfoStore(rootStore) {
  const store = new ScreenEditUserInfoStore(rootStore);
  return store;
}
