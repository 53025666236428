import axios from 'axios';
import { getBaseUrl } from './getBaseUrl';
import { addBreadcrumb } from './sentrySendException/addBreadcrumb';
import { sendExceptionToSentryAndReThrow } from './sentrySendException/sendExceptionToSentryAndReThrow';
import { logoutServer } from 'layouts/Dashboard/server/logoutServer';

export async function httpCall(url, data) {
    const baseUrl = getBaseUrl();
    const urlWithBase = `${baseUrl}${url}`;
    try {
        const res = await axios({
            url: urlWithBase,
            method: 'POST',
            data,
            withCredentials: true
        });

        return res.data;
    } catch (e) {

        if (e.response?.status === 401) {
            await logoutServer();
            window.location.reload();
            throw new Error("Token expired");
        }

        addBreadcrumb({ level: 'error', data: { url } });
        sendExceptionToSentryAndReThrow({ error: e, extra: { url } })
    }
}