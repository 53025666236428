/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@mui/styles";
import { Hidden } from "@mui/material";
import { useRootStore } from "commonStores/analytics/rootStoreContext";

const uvtalLogoSrc = 'https://d3mspq0y1r4vba.cloudfront.net/segment/46+-+Police/UVTAL_white_logo.png';

const useStyles = makeStyles(() => ({
    uvtalCenterLogo: {
        height: 35
    }
}));

export const CenterUvtalLogo = () => {
    const { userInfoStore } = useRootStore();
    const { isShowCenterUvtalLogo } = userInfoStore;
    const classes = useStyles();

    if (!isShowCenterUvtalLogo) {
        return null;
    }

    return (
        <Hidden smDown>
            <img
                alt="UvtalLogo"
                src={uvtalLogoSrc}
                className={classes.uvtalCenterLogo}
            />
        </Hidden>
    );
};