import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from "@mui/styles";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    toolTipIcon: {
        color: theme.palette.primary.main
    },
    container: {
        marginLeft: -40,
        width: 275
    }
}));

export const SummaryMonthlyReportSuppliersCouponsTitle = observer(() => {
    const classes = useStyles();


    return (
        <Grid item container xs={12} className={classes.container}>
            <Grid item >
                <InputLabel className={classes.label} shrink >{t("suppliers.ProjectManagerHome.MonthlyCouponsSummaryReportTitle")}</InputLabel>
            </Grid>
            <Grid item >
                <Tooltip title={t("suppliers.home.projectManagerCouponsReportSummaryToolTip")} >
                    <HelpIcon className={classes.toolTipIcon} />
                </Tooltip>
            </Grid>
        </Grid>
    )
});

