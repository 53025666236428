import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";

const useStyles = makeStyles((theme) => ({
  searchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rightSide: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
}));

export const Header = observer(() => {
  const classes = useStyles();
  const store = useScreenHomeStore();
  const businessName = store.rootStore.userInfoStore.currentBusinessId.businessName;

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item md={6} xs={12} className={classes.rightSide}>
        <Typography className={classes.title} component="h2" gutterBottom variant="overline"> {businessName} </Typography>
        <Typography component="h1" gutterBottom variant="h3"> {t("screenHome.overview.title")} </Typography>
      </Grid>
    </Grid>
  );
});
