export function getUserBalanceTableData(store) {
  const { userBalance } = store;
  const tableDataArray = userBalance
    ? userBalance.map((item) => [
        item.date,
        item.action,
        Number(item.cost),
        Number(item.total),
      ])
    : [];
  return tableDataArray;
}
