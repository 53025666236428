import React from 'react';
import { makeStyles } from '@mui/styles';
import { observer } from "mobx-react";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import { useScreenSendNotificationStore } from "../store/index";
import { t } from 'common/localization/translate';
import Typography from '@mui/material/Typography';
import { NotificationTypeError } from './notificationTypeError';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop:15
    },
    checkbox: {
        color: theme.palette.primary.main,
    }
}));

export const NotificationType = observer(() => {
    const classes = useStyles();
    const store = useScreenSendNotificationStore();

    const handleChangeInternal = (e) => {
        store.isInternalNot = e.target.checked;
        store.isEmptyNotificationType = false;
        store.isSentSelfNotification = false;
    };

    const handleChangeExternal = (e) => {
        store.isExternalNot = e.target.checked;
        store.isEmptyNotificationType = false;
        store.isSentSelfNotification = false;
    };
  
    return (
        <Grid>
            <Typography className={classes.title} gutterBottom>{t("support.sendNotificationScreen.notificationType")}</Typography>
            <FormControlLabel  control={<Checkbox  className={classes.checkbox} checked={store.isInternalNot} onChange={handleChangeInternal} />} label={t("support.sendNotificationScreen.notificationType:updates")} />
            <FormControlLabel  control={<Checkbox className={classes.checkbox} onChange={handleChangeExternal} checked={store.isExternalNot} />} label={t("support.sendNotificationScreen.notificationType:push")} />
            <NotificationTypeError/>
        </Grid>
    );
})