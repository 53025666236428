
import { t } from "common/localization/translate";

export const userDailyStepsAndGoalsErrors = {
    onlyNumbers: t("screenDailyStepsAndGoals.errors.onlyNumbers"),
    tooShort: t("screenDailyStepsAndGoals.errors.idNumberTooShort"),
    userNotExsist: t("screenDailyStepsAndGoals.errors.userNotExsist"),
    invalidInput: t("screenDailyStepsAndGoals.errors.invalidInput"),
    limitReached: t("screenDailyStepsAndGoals.errors.limitReached"),
}
