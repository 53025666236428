import React from "react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import { InputConfirmations } from "./components/inputConfirmations";
import { ButtonConfirmations } from "./components/confirmationsButton";
import Grid from "@mui/material/Unstable_Grid2";
import { useScreenConfirmationsUseresStore } from "../../store/index";
import { getErrorTextByErrorCode } from '../../store/logic/getErrorTextByErrorCode'
const useStyles = makeStyles(() => ({
    row: {
        width: "100%",
        display: "flex",
        marginLeft: 0.5
    },
}));
export const IdNumForm = () => {
    const classes = useStyles();
    const store = useScreenConfirmationsUseresStore();
    const { errorCode } = store;
    const errorText = getErrorTextByErrorCode(errorCode);
    const onSubmit = (e) => {
        e.preventDefault();
        store.submitIdNum();
    };

    return (
        <form className={classes.row} onSubmit={onSubmit}>
            <Grid className={classes.row} container lg={12} xs={12} spacing={1}>
                <InputConfirmations
                    errorText={errorText}
                    idInput={t("screenConfirmationUser.id")}
                />
                <ButtonConfirmations />
            </Grid>
        </form>
    );
}
