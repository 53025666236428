export const styles = {
    border: {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    },
    alignmentCenter: {
        horizontal: 'center',
        vertical: 'center'
    },
    darkBlueFill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '283C7C' },
    },
    grayFill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E4E4E4' },
    },
    whiteBold: {
        color: { argb: 'FFFFFF' },
        bold: true
    },
}