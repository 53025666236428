import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { t } from "common/localization/translate";
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { he } from "date-fns/locale";
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    label: {
        fontSize: 14,
        fontWeight: 'bold'
    }
}));

export const SelectDateProjectManagerSummaryCouponsReport = observer(() => {
    const classes = useStyles();
    const store = useScreenHomeStore();
    const defalutDate = moment().subtract(1, 'months').toDate();
    const maxDate = new Date(moment().subtract(1, 'month').endOf('month').toDate());
    const minDate = new Date(moment().subtract(1, 'years').startOf('month').toDate());

    const handleDateChange = (selectedDate) => {
        selectedDate = moment(selectedDate);
        store.selectedMonthCouponsSummaryReport = selectedDate > maxDate || selectedDate < minDate ? defalutDate : selectedDate.toDate();
    };

    return (
        <Grid item xs={12} spacing={3}>
            <InputLabel className={classes.label}> {t("suppliers.projectManagerHome.selectMonthAndYear")}</InputLabel>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                libInstance={moment}
                adapterLocale={he}
            >
            <MobileDatePicker
                defaultValue={defalutDate}
                minDate={minDate}
                maxDate={maxDate}
                views={["year", "month"]}
                value={store.selectedMonthCouponsSummaryReport || defalutDate}
                onChange={handleDateChange}
                slotProps={{ textField: { variant: 'standard' } }}
            />
            </LocalizationProvider>
        </Grid>
    )
});