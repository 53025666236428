import React from "react";
import { Typography, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    "& > *": {
      height: "100%",
      padding: theme.spacing(0.5),
    },
  },
}));

export const TitlesSendNotificationToUser = observer(() => {
  const subTitle = t("support.sendNotificationScreen.subtitle");
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <Typography component="h2" gutterBottom variant="overline">
        {t("screenSendNotificationToUser.title")}
      </Typography>
      <Typography variant="subtitle2">
        {subTitle}
      </Typography>
    </Grid>
  );
});
