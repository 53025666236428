
export function getClickByCouponTypeForChart(store) {

  const { amountOfClicksByCouponType } = store;
  if (!amountOfClicksByCouponType) {
    return;
  }
  const amountOfClicksByCouponTypeFormated = [];

  for (const couponType of amountOfClicksByCouponType) {
    const couponTypeFormatted = {
      couponTypeId: couponType.couponTypeId,
      label: couponType.couponTitle,
      valueExplaind: couponType.clicksNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      value: couponType.clicksNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      isActive :couponType.isActive,
    }

    amountOfClicksByCouponTypeFormated.push(couponTypeFormatted)
  }



  return amountOfClicksByCouponTypeFormated;
}
