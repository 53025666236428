
import { dashboardPaths } from '../../../../screens/loginV2/store/logic/dashboardPaths';
export function getIsWithoutSegmentPath(dashboardType) {
    if (dashboardType == dashboardPaths.SUPPORT || dashboardType == dashboardPaths.BUSINESS ||
        dashboardType == dashboardPaths.CONTROL_PANEL || dashboardType == dashboardPaths.SUPPLIERS) {
        return true;
    }
    return false;
}


