import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import MUIDataTable from "mui-datatables";
import { useScreenDailyStepsAndGoalsStore } from "../store";
import { InformationAboutStepsDataButton } from "./InformationAboutStepsDataButton";
import { SubTitleStepsAndGoals } from "./SubTitleStepsAndGoals";
import Grid from "@mui/material/Unstable_Grid2";
import { getTableData } from './getTableData';

export const DailyStepsAndGoalsTable = observer(() => {
  const store = useScreenDailyStepsAndGoalsStore();
  const segmentId = store.rootStore.userInfoStore.currentSegmentId;
  const { userDailyStepsAndGoalsTableData, errorCode, userName } = store;
  const { options, columns } = getTableData(segmentId);

  if (errorCode || (userDailyStepsAndGoalsTableData.length == 0 && !userName)) {
    return null;
  }

  return (
    <Grid>
      <br />
      <SubTitleStepsAndGoals />
      <br />
      <InformationAboutStepsDataButton />
      <MUIDataTable
        title={t("screenDailyStepsAndGoals.table.title", { userName })}
        data={userDailyStepsAndGoalsTableData}
        columns={columns}
        options={options}
      />
    </Grid>
  );
});
