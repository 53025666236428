import React from "react";
import { organizationRoutes } from "./organizationRoutes";
import { businessRoutes } from "./businessRoutes";
import { suppliersRoutes } from "./suppliersRoutes";
import { supportRoutes } from "./supportRoutes";

export const routes = [
  ...organizationRoutes,
  ...businessRoutes,
  ...supportRoutes,
  ...suppliersRoutes
];
