import { t } from "common/localization/translate";
import { sendNotificationToUserErrorCodes } from "./sendNotificationToUserErrorCodes";

export function getSendNotificationPopUpErrorMessageByErrorCode(sendNotificationPopUpErrorMessageErrorCode) {

    if (sendNotificationPopUpErrorMessageErrorCode === sendNotificationToUserErrorCodes.LIMIT_EXCEEDED) {
        return t("support.sendNotificationScreen.sendNotificationPopUpErrorMessage:limit");
    }

    if (sendNotificationPopUpErrorMessageErrorCode === sendNotificationToUserErrorCodes.NO_RUNNING_COMPETITION) {
        return t("support.sendNotificationScreen.sendNotificationPopUpErrorMessage:noCompetition");
    }

    return '';
}