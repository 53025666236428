import React from "react";
import { observer } from "mobx-react";
import { useScreenPurchasesStore } from "../store";
import DatePickerCommon from "common/ui/datePickerV2";

export const DatePicker = observer(() => {
  const store = useScreenPurchasesStore();

  const onChangeStartDate = (startDate) => {
    store.setStartDate(startDate);
    store.getScreenData();
  };

  const onChangeEndDate = (endDate) => {
    store.setEndDate(endDate);
    store.getScreenData();
  };

  return (
    <DatePickerCommon
      onChangeEndDate={onChangeEndDate}
      onChangeStartDate={onChangeStartDate}
    />
  );
});
