import React from "react";
import { makeObservable, observable } from "mobx";
import { getHomeScreenDataServer } from '../../home/store/server/getHomeScreenDataServer';
import { sendCouponCodes } from '../../home/store/server/sendCouponCodes';
import { getVerifyCouponCodesErrorText } from "../../home/store/logic/getVerifyCouponCodesErrorText";
import moment from 'moment';
import { getCouponTypesGroupServer } from "screens/controlPanel/addCouponType/store/server/getCouponTypesGroupServer";

const ProjectManagerSupplierStoreContext = React.createContext({});
export const ProjectManagerSupplierStoreProvider = ProjectManagerSupplierStoreContext.Provider;
export const useProjectManagerSupplierStore = () =>
  React.useContext(ProjectManagerSupplierStoreContext);

class ProjectManagerSupplierStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.initProperties();
    makeObservable(this, {
      isFetching: observable,
      codes: observable,
      month: observable,
      isValidOtpCode: observable,
      couponTypeId: observable,
      selectedDate: observable,
      changeMonthPopUp: observable,
      changeMonthDecision: observable,
      isSuccessEnterCodes: observable,
      couponTypes: observable,
      couponTypeGroupId: observable,
      couponTitle: observable,
      chosenCouponType: observable,
      chosenSupplier: observable,
      isProjectManager: observable,
      verifyCodesErrorText: observable,
      totalCodesLoaded: observable,
      validCodesAmount: observable,
      exceptionalCodesAmount: observable,
      isFetchingLoadingCodes: observable,
      isFetchingFinishLoadingCodes: observable,
      isFetchingManagerReport: observable,
      projectManagerEmail: observable,
      otpPolicyPopUp: observable,
    });
  }

  initProperties() {
    this.isFetching = false;
    this.codes = null;
    this.month = null;
    this.isValidOtpCode = null;
    this.couponTypeId = null;
    this.selectedDate = null;
    this.changeMonthPopUp = false;
    this.changeMonthDecision = null;
    this.isSuccessEnterCodes = false;
    this.couponTypes = [];
    this.couponTypeGroupId = null;
    this.couponTitle = null;
    this.chosenCouponType = null;
    this.chosenSupplier = null;
    this.isProjectManager = false;
    this.verifyCodesErrorText = null;
    this.totalCodesLoaded = 0;
    this.validCodesAmount = 0;
    this.exceptionalCodesAmount = 0;
    this.isFetchingLoadingCodes = false;
    this.isFetchingFinishLoadingCodes = false;
    this.isFetchingManagerReport = false;
    this.projectManagerEmail = null;
    this.otpPolicyPopUp = false;
  }

  getScreenData = async () => {
    const data = await getHomeScreenDataServer();
    if (data.extra.isProjectManager) {
      this.suppliersList = data.extra.suppliers;
      this.chosenSupplier = data.extra.suppliers[0].supplier_id;
      this.isProjectManager = data.extra.isProjectManager;
      await this.getCouponsBySupplier(this.chosenSupplier);
      return;
    }
  }
  
  getCouponsBySupplier = async () => {
    const couponTypesForSupplier  = await getCouponTypesGroupServer({supplierId: this.chosenSupplier, segmentId: this.isProjectManager});
    this.couponTypes = couponTypesForSupplier.length == 0 ? [{couponTypeId: null, couponTypeGroupId: null, couponTitle: 'לספק זה אין הטבות'}] : couponTypesForSupplier;
    this.chosenCouponType = this.couponTypes[0].couponTypeId;
    this.couponTypeGroupId = this.couponTypes[0].groupId;
    this.couponTitle = this.couponTypes[0].couponTitle;
  }

  sendCouponCodes = async () => {
    this.isFetchingLoadingCodes = true;
    this.verifyCodesErrorText = null;
    this.month = this.month || moment().subtract(1, 'months').toDate();
    this.refund = this.refund || 0;
    const data = await sendCouponCodes({codes: this.codes, date: this.month, refund: this.refund, couponTypeGroupId:this.couponTypeGroupId, couponTitle: this.couponTitle, isProjectManager: true, supplierId: this.chosenSupplier});
    if (data.isSuccess) {
      this.totalCodesLoaded = data.extra.totalCodesLoaded;
      this.validCodesAmount = data.extra.validCodesAmount || 0;
      this.exceptionalCodesAmount = data.extra.exceptionalCodesAmount || 0;
      this.isSuccessEnterCodes = data.isSuccess;
    }
    this.codes=null;
    document.getElementById('codes').value=null;
    this.verifyCodesErrorText = getVerifyCouponCodesErrorText(data.errorCode);
    this.isFetchingLoadingCodes = false;
  }
}

export function createProjectManagerSupplierStore(rootStore) {
  const store = new ProjectManagerSupplierStore(rootStore);
  return store;
}