import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { observer } from "mobx-react-lite";
import { useScreenSwitchBusinessStore } from "../index";
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 16,
      fontFamily: "Assistant",
      fontWeight: "bold",
    },
  },
}));

export const FailedToChangeBusinessErrorPopUp = observer(() => {
  const store = useScreenSwitchBusinessStore();
  const classes = useStyles();

  const handleClose = () => {
    store.isFailedToChangeBusinessErrorPopUpOpen = false;
  };

  return (
    <div>
      <Dialog open={store.isFailedToChangeBusinessErrorPopUpOpen}>
        <DialogTitle className={classes.title}>
          {t("switchBusiness.ErrorPopUpTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("switchBusiness.failedToChangeBusiness")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("suppliers.supplierHome.verifyCodesOkPopUp")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});