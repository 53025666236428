import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { observer } from "mobx-react";
import { EnterCodesPreviousMonth } from './enterCodes';
import { AskForOtpCodeToEnterOnPreviousMonth } from './otpScreen/askForOtpCodeToEnterOnPreviousMonth';

export const SupplierPreviousMonths = observer(() => {
    return (
        <Grid container  >
            <AskForOtpCodeToEnterOnPreviousMonth />
            <EnterCodesPreviousMonth/>
        </Grid>
    )
});

