import { t } from "common/localization/translate";

const periodTypes = {
  LAST_SEVEN_DAYS: "LAST_SEVEN_DAYS",
  LAST_THIRTY_DAYS: "LAST_THIRTY_DAYS",
  LAST_THREE_MONTHS: "LAST_THREE_MONTHS",
  LAST_SIX_MONTHS: "LAST_SIX_MONTHS",
};

export const specialPeriodTypes = { 
  LAST_NINETY_DAYS: "LAST_NINETY_DAYS"
}

export function getPeriodsForNewUsersChart() {
  return [
    {
      type: periodTypes.LAST_SIX_MONTHS,
      text: t("screenHome.newUsersChart.dropDownOption.6Months")
    },
    {
      type: periodTypes.LAST_THREE_MONTHS,
      text: t("screenHome.newUsersChart.dropDownOption.3Months")
    },
    {
      type: periodTypes.LAST_THIRTY_DAYS,
      text: t("screenHome.newUsersChart.dropDownOption.30Days")
    },

    {
      type: periodTypes.LAST_SEVEN_DAYS,
      text: t("screenHome.newUsersChart.dropDownOption.7Days")
    }
  ];
}
