import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenTraineesInfoStore, ScreenTraineesInfosStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import { Redirect } from "react-router-dom";
import { TraineesTable } from "./components/traineesTable";
import { Header } from "./components/header";
import { SendExcelMailPopup } from "./components/sendExcelMailPopup";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flex: 1,
  },
}));

export const TraineesInfo = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenTraineesInfoStore(rootStore));

  const classes = useStyles();
  useEffect(() => { store.getScreenData(); }, []);

  if (!rootStore.userInfoStore.isShowTrainesInfoScreen) {
    return <Redirect to={{ pathname: `/${dashboardPaths.ORGANIZATION}/home` }} />
  }
  return (
    <ScreenTraineesInfosStoreProvider value={store}>
      <Page className={classes.root} title={t("screenTrainesInfo.table.title")}>
        <Header />
        <div style={{height: 10}}/>
        <TraineesTable />
        <SendExcelMailPopup />
      </Page>
    </ScreenTraineesInfosStoreProvider>
  );
});
