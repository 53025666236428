import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Page } from "components";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import MUIDataTable from "mui-datatables";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import {
  createScreenCouponsInInventoryStore,
  ScreenCouponsInInventoryStoreProvider
} from "./store";
import DatePicker from "common/ui/datePickerV2";
import { t } from "common/localization/translate";
import { ShowNowInInventoryButton } from "./components/showNowInInventoryButton";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { getIsRtl } from "utils/direction/getIsRtl";
import { getTableDataOptions } from "../purchases/store/logic/getTableDataOptions";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    flex: 1
  },
  row: {
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  container: {
    "& > *": {
      height: "100%"
    }
  },
  searchButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 15,
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginRight: 20
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
    marginLeft: props => props.isRtl ? 0 : 15,
    marginRight: props => props.isRtl ? 15 : 0,
    fontSize: "25px",
  },
  toolTipGrid: {
    marginRight: props => props.isRtl ? 0 : 'auto',
    marginLeft: props => props.isRtl ? 'auto' : 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: '25px',
    marginTop: 5,
  },
}));

export const CouponsInInventory = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() =>
    createScreenCouponsInInventoryStore(rootStore)
  );
  const isRtl = getIsRtl();
  const classes = useStyles({ isRtl });

  useEffect(() => {
    if (rootStore.userInfoStore.currentSegment && rootStore.userInfoStore.currentSegment.segmentId) {
      store.getScreenData(true);
    }
  }, []);

  const onChangeStartDate = startDate => {
    store.setStartDate(startDate);
    store.getScreenData();
  };

  const onChangeEndDate = endDate => {
    store.setEndDate(endDate);
    store.getScreenData();
  };

  const screenToolTip = (
    <div>
      {t("organizationDashboard.couponsInInventory.tooltip1")} <br />
      {t("organizationDashboard.couponsInInventory.tooltip2")} <br />
    </div>
  );

  const { purchasesTableData, isFetching } = store;
  const { title, options, columns } = getTableData(isFetching);
  return (
    <ScreenCouponsInInventoryStoreProvider value={store}>
      <Page
        className={classes.root}
        title={t("screenCouponsInInventory.table.title")}
      >
        <Grid className={classes.header} item lg={6} sm={12}>
          <Typography className={classes.title} component="h2" gutterBottom variant="overline">
            {title}
          </Typography>
          <Grid className={classes.toolTipGrid}>
            <Tooltip title={screenToolTip} classes={classes}>
              <HelpIcon className={classes.toolTipIcon} />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container lg={12} sm={12} className={classes.row}>
          <Grid item lg={2} sm={12} className={classes.row}>
            <ShowNowInInventoryButton />
          </Grid>
          <Grid item lg={3} sm={12} className={classes.row}>
            <DatePicker
              onChangeEndDate={onChangeEndDate}
              onChangeStartDate={onChangeStartDate}
            />
          </Grid>
        </Grid>

        <MUIDataTable
          className={classes.table}
          title={title}
          data={purchasesTableData}
          columns={columns}
          options={options}
        />
      </Page>
    </ScreenCouponsInInventoryStoreProvider>
  );
});

const getTableData = (isFetching) => {
  const tableDataOptions = getTableDataOptions();
  const columns = [
    {
      name: "title",
      label: t("screenCouponsInInventory.table.col.couponTitle"),
      options: tableDataOptions
    },
    {
      name: "businessName",
      label: t("screenCouponsInInventory.table.col.businessName"),
      options: tableDataOptions
    },
    {
      name: "count",
      label: t("screenCouponsInInventory.table.col.couponCount"),
      options: tableDataOptions
    }
  ];

  const options = {
    filter: false,
    download: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    downloadOptions: { filename: 'couponsInInventory.csv' },
    print: false,
    viewColumns: false,
    responsive: "scrollMaxHeight",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isFetching ? "Loading..." : 'Sorry, no matching records found',
      }
    }
  };
  const title = t("screenCouponsInInventory.table.title");
  return { title, options, columns };
};
