import React from "react";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { makeStyles } from "@mui/styles";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
  toolTipIcon: {
    color: theme.palette.primary.main,
  },
}));

export const InformationAboutStepsDataButton = observer((props) => {
  const classes = useStyles();
  return (
      <Tooltip title={t("screenDailyStepsAndGoals.informationButton.recover")} classes={classes}>
        <HelpIcon className={classes.toolTipIcon} />
      </Tooltip>
  );
});
