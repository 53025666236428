import React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";



export const Home = observer(() => {
  return  <Redirect to={{ pathname: `/${dashboardPaths.SUPPORT}/getUserCoupons` }} />
});
