import React, { useState } from 'react';
import { observer } from "mobx-react";
import { DataPage } from './dataPage';
import { FormWrapper } from './formWrapper';
import { getErrorTextByErrorCode } from 'screens/organization/healthAndFitnessData/store/logic/getErrorTextByErrorCode';
import { InputMeasurements } from '../../inputMeasurements';
import { StageWithDropdown } from '../../stageWithDropdown';
import { SubmitButtonAndErrorText } from './submitButtonAndErrorText';
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from 'screens/organization/healthAndFitnessData/store/storeHealthAndFitnessDataStoreContext';

export const FinishMeasurementsData = observer(() => {
    const [isShowingData, setIsShowingData] = useState(false);
    const content = isShowingData ? <InputMeasurements stage={"finishMeasurements"} /> : null;
    const store = useHealthAndFitnessDataStore();
    const errorText = getErrorTextByErrorCode(store.errorCodeSaveFinish);
    const isError = store.errorCodeSaveFinish ? true : false;

    const handleOnClick = (e) => {
        e.preventDefault();
        toggleIsShowingData();
    }

    const onSubmit = (e) => {
        e.preventDefault();
        store.submitSectionData('finish');
    };

    const toggleIsShowingData = () => {
        setIsShowingData(prevState => !prevState);
    }

    return (
        <FormWrapper onSubmit={onSubmit}>
            <DataPage>
                <StageWithDropdown
                    isShowingData={isShowingData}
                    title={t("screenHealthAndFitnessData.data.finish")}
                    onClick={handleOnClick}
                />
                {content}
                <SubmitButtonAndErrorText
                    isVisible={isShowingData}
                    text={t("screenHealthAndFitnessData.data.finish")}
                    isError={isError}
                    errorText={errorText}
                />
            </DataPage>
        </FormWrapper>
    );
});