import React from "react";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleCube from "common/ui/simpleCube";
import { t } from "common/localization/translate";
import { useScreenHomeStore } from "../store";

const useStyles = makeStyles(theme => ({
  row: {
    width: "100%",
    paddingLeft: 10
  },
  reversedIcon: {
    color: theme.palette.primary.main
  },
  img: {
    height: 35
  }
}));

export const MonthlyImprovementPercentageCube = observer(() => {
  const {
    monthlyImprovement,
    isFetchingSegmentImprovement
  } = useScreenHomeStore();
  const segmentImprovementPercentage = monthlyImprovement || 0;
  const segmentImprovementValueText =
    segmentImprovementPercentage >= 0
      ? t("screenHome.totalNumbersCubesRow.avgImprovement.display", {
        number: segmentImprovementPercentage
      })
      : t("screenHome.totalNumbersCubesRow.avgImprovement.Minusdisplay", {
        number: segmentImprovementPercentage * -1
      });

  const classes = useStyles();
  const store = useScreenHomeStore();
  const { selectedSegmentName } = store;
  return (
    <Grid item xs={12} md={6} lg className={classes.row}>
      <SimpleCube
        isReversed={true}
        isFetching={isFetchingSegmentImprovement}
        label={t("screenHome.totalNumbersCubesRow.avgImprovement.monthly", {
          selectedSegmentName
        })}
        value={segmentImprovementValueText}
        icon={<CallMadeIcon className={classes.reversedIcon} />}
        withoutMargin={true}
      />
    </Grid>
  );
});
