export function getPurchasesTableData(store) {
  const { couponsPurchasesCount } = store;
  const tableDataArray = couponsPurchasesCount
    ? couponsPurchasesCount.map((item) => {
        const array = [item.title, item.businessName, Number(item.count)];
        if (item.segmentName) {
          array.push(item.segmentName);
        }
        return array;
      })
    : [];
  return tableDataArray;
}
