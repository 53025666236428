import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    slash: { color: "#546e7a95", fontSize: 45 },
    slashContainer: {
        flexGrow: 1,
        textAlign: 'center',
        justifyContent: 'center',
        paddingTop: 16
    }
}));

export const BloodPressureSlash = () => {
    const classes = useStyles();
    return (
        <div className={classes.slashContainer}>
            <Typography gutterBottom className={classes.slash}>{'/'}</Typography>
        </div>
    );
};