import React from "react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";
import { useScreenHomeStore } from "../../../store";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
errorText:{
    fontSize:10,
    color:'red'
}
}));

export const ErrorSentReportFaild = observer(() => {
    const store = useScreenHomeStore();
    const classes = useStyles();
    if(!store.errorReportFaildText){
        return null;
    }
    return (
            <Grid item xs={12}>
                <Typography className = {classes.errorText} >{store.errorReportFaildText}</Typography>
            </Grid>
    )
});

