import Excel from "exceljs/dist/exceljs";
import { t } from "common/localization/translate";
import { setSheetProps } from "../../../../organization/home/store/logic/createExcel/setSheetProps";
import { createSheetUserBalance } from "./createSheetUserBalance";
import { downloadWorksheet } from "../../../../organization/healthAndFitnessData/store/logic/createExcel/downloadWorksheet";

const DEFAULT_FILE_NAME = 'מעקב עו״ש של משתמש בת.ז';

export async function onUserBalanceExcelDownload({ userIdentifier, userBalanceTableData }) {
    const workbook = new Excel.Workbook();
    const fileName = `${DEFAULT_FILE_NAME} ${userIdentifier}`

    if (userBalanceTableData) {
        const sheetUserBalance = workbook.addWorksheet(t("screenUserBalance.title"));
        setSheetProps([sheetUserBalance]);
        createSheetUserBalance({ sheetUserBalance, userBalanceTableData })
    }

    downloadWorksheet(workbook, fileName);
}
