import React from 'react';
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from '../store/storeHealthAndFitnessDataStoreContext';

const useStyles = makeStyles(() => ({
    header: {
        fontSize: "22px",
        lineHeight: "18px",
        marginTop: 40,
        marginBottom: 20,
        fontWeight: 'bold',
    },
}));

export const SearchResultHeader = observer(() => {
    const classes = useStyles();
    const store = useHealthAndFitnessDataStore();

    if (!store.userName || store.isFetchingCopData) {
        return null;
    }

    return (
        <Typography className={classes.header} component="h2" variant="overline" gutterBottom>
            {t("screenHealthAndFitnessData.officerData.header")}
        </Typography>
    );
});