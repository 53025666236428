import { writeToRow } from "./writeToRow";
import { t } from 'common/localization/translate';


export function createSheetAppOpenRate(sheetAppOpenRate, storeHome) {

    const { formattedAppOpenData } = storeHome;

    const date = t("screenHome.excel.appOpen.date");
    const count = t("screenHome.excel.appOpen.entries");
    const countDistinct = t("screenHome.excel.appOpen.distinctCount");

    writeToRow(sheetAppOpenRate, [date, count, countDistinct], 1);
    let rowNumber = 2;
    formattedAppOpenData.forEach(({ date, count, distinctCount }) => {
        writeToRow(sheetAppOpenRate, [date, count, distinctCount], rowNumber);
        rowNumber++;
    });

}