import HomeIcon from "@mui/icons-material/Home";
import { t } from "common/localization/translate";
import { dashboardPaths } from "screens/loginV2/store/logic/dashboardPaths";
import EmojiSymbolsIcon from '@mui/icons-material/EmojiSymbols';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LabelOffIcon from '@mui/icons-material/LabelOff';
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";

export default [
  {
    title: "תפריט",
    pages: [
      {
        title: t("sideBar.home.title"),
        href: `/${dashboardPaths.SUPPLIERS}/home`,
        icon: HomeIcon,
      },
      {
        title: t("suppliers.supplierAndCouponReports.title"),
        href: `/${dashboardPaths.SUPPLIERS}/supplierReports`,
        icon: ConfirmationNumberIcon
      },
      {
        title: t("suppliers.sideBar.validations"),
        href: `/${dashboardPaths.SUPPLIERS}/validationCodes`,
        icon: EmojiSymbolsIcon,
      },
      {
        title: t("suppliers.projectManagerHome.title"),
        href: `/${dashboardPaths.SUPPLIERS}/billingReportSystem`,
        icon: AccountTreeIcon,
      },
      {
        title: t("suppliers.billingReportSystem.projectManagerSupplier.title"),
        href: `/${dashboardPaths.SUPPLIERS}/projectManagerSupplier`,
        icon: LabelOffIcon,
      },
    ],
  },
];
