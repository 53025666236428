import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { useScreenCouponsStore } from "../../store/index";
import { observer } from "mobx-react-lite";
import { t } from "common/localization/translate";
import { couponsActionTypes } from "../../store/logic/couponsActionTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.white,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,
    "& h6": {
      color: theme.palette.white,
      fontSize: 14,
    },
  },
  content: { marginTop: 8 },
  dialogDiv: {
    width: 600,
    height: 250,
  },
  buttons: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    color: theme.palette.white,
    marginLeft: 21,
    marginTop: 18,
    "&:hover": {
      backgroundColor: theme.palette.primary.secondGraphColor,
      color: theme.palette.white,
    },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
  }
}));

export const SuccessCancelCouponPopUp = observer(() => {
  const store = useScreenCouponsStore();
  const { isSuccessCancelCoupon, setIsSuccessCancelCoupon, actionType } = store;
  const classes = useStyles();
  const handleClose = () => {
    setIsSuccessCancelCoupon(false);
  };

  let mainContentTitle = t("screenCouponsUser.popUp.cancelCouponSuccess");
  let mainContentSubTitle = t("screenCouponsUser.popUp.cancelCouponSuccessMessege");

  if (actionType === couponsActionTypes.SUBMIT_USER_COUPON_FOR_DELETION_AFTER_PURCHASE) {
    mainContentTitle = t("screenCouponsUser.popUp.submissionCouponSuccess");
    mainContentSubTitle = t("screenCouponsUser.popUp.submissionCouponSuccessMessage");
  }

  return (
    <div>
      <Dialog
        className={classes.dialog}
        open={isSuccessCancelCoupon}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.dialogDiv}>
          <DialogTitle className={classes.root} id="responsive-dialog-title">
            {t("screenCouponsUser.cancelCoupon")}
          </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText>
              {mainContentTitle}
              <br />
              {mainContentSubTitle}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.buttonsContainer}>
            <Button
              className={classes.buttons}
              size="small"
              type="submit"
              variant="contained"
              onClick={handleClose}
            >
              {t("screenCouponsUser.popUp.closeButton")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
});
