import React from "react";
import { observer } from "mobx-react";
import { handleInputNonNegativeValuesOnly } from "../handleInputNonNegativeValuesOnly";
import { InputForm } from "./inputForm";
import { t } from "common/localization/translate";
import { useHealthAndFitnessDataStore } from "../../../store/storeHealthAndFitnessDataStoreContext";

export const InputPushups = observer(({ stage }) => {
    const store = useHealthAndFitnessDataStore();
    const value = store[stage].pushups;

    const handleChange = (event) => {
        const value = handleInputNonNegativeValuesOnly(event.target.value);
        store.setPushups(value, stage);
    };

    return (
        <InputForm
            onHandleChange={handleChange}
            label={t("screenHealthAndFitnessData.pushups.label")}
            value={value}
            fieldName={`${stage}Pushups`}
        />
    );
});