export function getCouponsTableData(store) {
    const { userCoupons } = store;
    const tableDataArray = userCoupons 
      ? userCoupons.map(item => [
          item.date,
          item.businessName,
          item.supplierName,
          item.couponName,
          item.couponCode,
          item.expireDate,
        ])
      : [];
     return tableDataArray ;
  }
  