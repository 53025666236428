import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Page } from "components";
import { observer } from "mobx-react";
import { useRootStore } from "commonStores/analytics/rootStoreContext";
import { createScreenHomeStore, ScreenHomeStoreProvider } from "./store";
import { t } from "common/localization/translate";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { SupplierHome } from './components/supplierHome';
import { ProjectManagerHome } from './components/projectManagerHome';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
    height: "100%",
  }
}));

export const Home = observer(() => {
  const rootStore = useRootStore();
  const [store] = useState(() => createScreenHomeStore(rootStore));
  const classes = useStyles();
  useEffect(() => {
    store.getScreenData();
  }, []);

  const { isFetching } = store;

  return (
    <ScreenHomeStoreProvider value={store}>
      <LoadingSpinner isFetching={isFetching} >
        <div>
          <Page className={classes.root} title={t("suppliers.home.suuplierDashboardTitle")}>
            <ProjectManagerHome />
            <SupplierHome />
          </Page>
        </div>
      </LoadingSpinner>
    </ScreenHomeStoreProvider>
  )
});