import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import { useScreenHideCouponTypesStore } from "../store";
import { makeStyles } from "@mui/styles";
import { t } from 'common/localization/translate';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        fontSize: 16,
        fontFamily: 'Assistant',
        fontWeight: 'bold'
    },
    buttons: {
        fontSize: 14,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.main,
        marginLeft: 10,
        "&:hover": {
          color: theme.palette.white,
          backgroundColor: theme.palette.primary.secondGraphColor
        }
      },
}));

export const AreYouSureHideCouponTypePopUp = observer(() => {
    const store = useScreenHideCouponTypesStore();
    const classes = useStyles();
    

    const handleClose = () => {
        store.isAreYouSureHideCouponTypePopUp= false;
    };

    const handleHideCoupon = () =>{
        store.isAreYouSureHideCouponTypePopUp = false;
        store.hideCouponType();
    }

    return (
        <div>
            <Dialog open={store.isAreYouSureHideCouponTypePopUp} >
                <DialogTitle className={classes.title} >{t("organizationDashboard.hideCouponType.AreYouSurePopUpTitle")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t("organizationDashboard.hideCouponType.AreYouSurePopUpContent", {couponTitle:store.couponTypeTitle})}
                    <br></br>{t("organizationDashboard.hideCouponType.AreYouSurePopUpContent2")}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleHideCoupon} className={classes.buttons} autoFocus >{t("general.yes")}</Button>
                    <Button onClick={handleClose} className={classes.buttons} autoFocus >{t("general.no")}</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
})