import React from "react";
import { observer } from "mobx-react";
import IconButton from '@mui/material/IconButton';
import { useScreenAppOpenByHoursStore } from "../store/index";
import { getAppOpenByHoursExcel } from "../store/logic/createExel/getOpenAppByHoursExcel";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { t } from "common/localization/translate";
import Tooltip from '@mui/material/Tooltip';


export const DownloadExcelButton = observer(() => {
    const store = useScreenAppOpenByHoursStore();
    const { isFetching } = store;
    const onClickDownloadToExcel = () => {
        getAppOpenByHoursExcel(store);
    };
    return (
        <Tooltip title={t("screenAppOpenByHours.downloadExcelButton.title")} arrow>
            <IconButton disabled={isFetching} aria-label="download">
                <CloudDownloadIcon cursor="pointer" color="action" onClick={onClickDownloadToExcel} />
            </IconButton>
        </Tooltip>
    );
});



