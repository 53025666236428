import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from "@mui/styles";
import { t } from "common/localization/translate";
import { InfoText } from "../infoText";
import { useScreenEntitlementStatusStore } from "../../store";

export const OtpData = observer(() => {
    const classes = useStyles();
    const store = useScreenEntitlementStatusStore();

    if (!store.otpCode) {
        return null;
    }

    return (
        <Grid >
            <Typography className={classes.title}>{t('screenEntitlementData.otpTitle')}</Typography>
            <InfoText description={t('screenEntitlementData.otpSent')} value={store.otpSentAt} />
            <InfoText description={t('screenEntitlementData.otpCode')} value={store.otpCode + '**'} />
            <InfoText description={t('screenEntitlementData.otpStatus')} value={store.isExpired ? t('screenEntitlementData.otpStatus.expired') : t('screenEntitlementData.otpStatus.active')} />
        </Grid>
    );
});

const useStyles = makeStyles(() => ({
    title: {
        fontSize: "14px",
        display: 'inline-block',
        paddingLeft: 5,
        fontWeight: 900,
        textDecoration: 'underline'
    }
}));