import { getMoment } from "screens/organization/appOpenByHours/store/logic/getMoment";

export function getStaffInfoTableData(store) {
  const { staffList } = store;
  const moment = getMoment();
  const getSignupStatusText = (item)=> item.isSignUp ? 'מחובר' : 'לא מחובר';
  const getContentAmount = (item)=> item.countReadAtricleInLast6Month ? Number(item.countReadAtricleInLast6Month) : '---';
  const getDate = (date)=> date ? moment(date).format('DD/MM/YYYY HH:mm')  : '---';
  const tableDataArray = staffList
  ? staffList.map((item) => [
    item.satffMemberNameAtExternalSheetData,
    item.phone || '---', 
    getSignupStatusText(item), 
    getDate(item.createdAt), 
    getDate(item.lastOpen), 
    item.email, 
    item.userName, 
    item.avgStepsLast30Days || 'לא התבצעה המרת צעדים בתקופה זו', 
      getDate(item.lastDepositDate), 
      item.countAppOpenLast30Days || 0, 
      item.countPurchaseRumbleAcoSystemCoupons || 0, 
      getDate(item.lastPurchaseAtidCouponDate), 
      item.lastPurchaseAtidCouponName || '---', 
      getDate(item.lastArticleReadDate),
      item.lastArticleReadName || '---', 
      getContentAmount(item),
    ].concat(item.groupNames)
    )
    : [];
    return tableDataArray;
}
