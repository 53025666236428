import React from "react";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { observer } from "mobx-react";
import { t } from "common/localization/translate";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    buttons: {
        backgroundColor: theme.palette.primary.main,
        fontSize: 15,
        width: 230,
        height: 40,
        color: theme.palette.white,
        marginRight: 5,
        marginTop: 5,
        "&:hover": {
            backgroundColor: theme.palette.primary.white,
        }
    }
}));

export const LoadingCodesButton = observer((props) => {
    const { store } = props;
    const classes = useStyles();
    const onClick = () => {
        store.sendCouponCodes();
    }

    const loadCodesButtonContent = store.isFetchingLoadingCodes ? <CircularProgress color='divider' size={20} /> : t("suppliers.supplierHome.sendCodesButton");
    return (
        <Grid item xs={12} spacing={3}>
            <Button variant="contained" className={classes.buttons} onClick={onClick}>{loadCodesButtonContent}</Button>
        </Grid>
    )
});