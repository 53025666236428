import React from 'react';
import { observer } from "mobx-react";
import { LoadingSpinner } from "common/ui/loadingSpinner";
import { useHealthAndFitnessDataStore } from '../store/storeHealthAndFitnessDataStoreContext';

export const LoadingCopDataSpinner = observer(() => {
    const store = useHealthAndFitnessDataStore();

    if (store.isFetchingCopData) {
        return <LoadingSpinner isFetching={store.isFetchingCopData} height="20px" />;
    }

    return null;
});