import React from 'react';
import Grid from "@mui/material/Unstable_Grid2";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { UserIdNum } from './components/userIdNum';
import { UserName } from './components/userName';
import { useHealthAndFitnessDataStore } from '../../store/storeHealthAndFitnessDataStoreContext';

const useStyles = makeStyles(() => ({
    root: { marginBottom: 15 }
}));

export const PersonalInfo = observer(() => {
    const classes = useStyles();
    const store = useHealthAndFitnessDataStore();

    if (!store.userName || store.isFetchingCopData) {
        return null;
    }

    return (
        <Grid container xs={12} sm={8} spacing={5} className={classes.root}>
            <UserIdNum />
            <UserName />
        </Grid>
    );
});